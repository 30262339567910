import React from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import { SecondaryButton, PrimaryButton, Icon } from '@hz-design-system/web-ui';

type TSaveSearchButtonProps = {
  onClick: () => void;
  disabled: boolean;
  asPrimary: boolean;
  customClassName: string | undefined;
  smallButton: boolean;
};

const SaveSearchButton = ({ onClick, disabled, asPrimary, customClassName, smallButton }: TSaveSearchButtonProps) => {
  const { t } = useI18nContext();
  const saveText = smallButton ? t('saveSearch.buttonSaveShort') : t('saveSearch.buttonSaveLong');
  const savedText = smallButton ? t('saveSearch.buttonSavedShort') : t('saveSearch.buttonSavedLong');
  const buttonText = disabled ? savedText : saveText;
  const ButtonKind = asPrimary ? PrimaryButton : SecondaryButton;
  const iconColor = asPrimary ? 'text-primary-inverse' : 'text-primary';

  return (
    <ButtonKind
      size={smallButton ? undefined : 'small'}
      onClick={onClick}
      disabled={disabled}
      className={customClassName}
      data-testid="savedSearchButton"
    >
      <Icon name="notification" color={iconColor} />
      {buttonText}
    </ButtonKind>
  );
};

export default SaveSearchButton;
