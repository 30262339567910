export const getCurrentPage = ({ limit, offset }) => Math.ceil(offset / limit) + 1;

const paginationHelper = (pagination, totalResults) => {
  const { limit, offset } = pagination;
  return {
    pageSize: +limit,
    resultsCount: totalResults,
    currentPage: getCurrentPage({ limit, offset }),
  };
};

export default paginationHelper;
