'use client';

import React from 'react';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { ListingSellerExternalLink } from '@/client/components/Listings/atoms';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

interface SellerExternalWebsiteProps {
  sellerWebsiteUrl: string;
}

/**
 * This is link to visit the website
 * of the seller providing they have provided one.
 * Importantly this only applies to the mobile view.
 */
const SellerExternalWebsite: React.FC<SellerExternalWebsiteProps> = ({ sellerWebsiteUrl }) => (
  <span
    suppressHydrationWarning
    className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-seller-external-link' })}
  >
    <ListingSellerExternalLink
      url={sellerWebsiteUrl}
      className={getSuitCSSClasses({ namespace: 'hz', name: 'TextLink' })}
    >
      <Trans tagName="span" i18nKey="listings.visit" />
    </ListingSellerExternalLink>
  </span>
);

export default SellerExternalWebsite;
