import { getURlWithoutJPG } from '@ecg-marktplaats/media-util-js-lib';

import categories from '@/constants/categories';

const NUMBER_OF_CENTS_IN_A_EURO = 100;
const MIN_PRICE_EUROS = 500;

const MIN_PRICE_CENTS = MIN_PRICE_EUROS * NUMBER_OF_CENTS_IN_A_EURO;

import {
  HOUSE_ENERGY_LABEL_KEY,
  HOUSE_NUMBER_OF_ROOMS_KEY,
  HOUSE_SQUARE_METERS_KEY,
  HOUSE_TYPE_KEY,
  HOUSE_CITY_KEY,
  HOUSE_REGION_KEY,
  HOUSE_NUMBER_OF_BEDROOMS_KEY,
} from '@/HOC/constants/housesAndRooms';

import { CONDITION } from '@/HOC/constants/hashWords';

const energySchemaMapping = {
  A: 'EUEnergyEfficiencyCategoryA',
  'A+': 'EUEnergyEfficiencyCategoryA1Plus',
  'A++': 'EUEnergyEfficiencyCategoryA2Plus',
  'A+++': 'EUEnergyEfficiencyCategoryA3Plus',
  B: 'EUEnergyEfficiencyCategoryB',
  C: 'EUEnergyEfficiencyCategoryC',
  D: 'EUEnergyEfficiencyCategoryD',
  E: 'EUEnergyEfficiencyCategoryE',
  F: 'EUEnergyEfficiencyCategoryF',
  G: 'EUEnergyEfficiencyCategoryG',
};

const conditionSchemaMapping = {
  Nieuw: 'NewCondition',
  'Zo goed als nieuw': 'UsedCondition',
  Gebruikt: 'UsedCondition',
};

const getItemListLdSchema = (listings, searchRequest) => {
  const itemList = [];

  const searchedL1CategoryId = searchRequest?.categories?.l1Category?.id;
  const houseCategory = searchedL1CategoryId === categories.HUIZEN_EN_KAMERS;

  const listingFilter = (listing) => listing.priceInfo.priceCents >= MIN_PRICE_CENTS;

  const filteredListings = listings.filter(listingFilter);

  if (!filteredListings.length) {
    return null;
  }

  listings.forEach((listing, index) => {
    const listItem = {
      '@type': houseCategory ? ['Product', 'Accommodation'] : ['Product'],
      name: listing.title,
      description: listing.description,
      image: listing.imageUrls?.length ? getURlWithoutJPG(listing.imageUrls[0]) : undefined,
      url: listing.vipUrl,
      offers: {
        '@type': 'Offer',
        availability: 'https://schema.org/InStock',
        price: listing.priceInfo.priceCents,
        priceCurrency: 'EUR',
      },
    };

    if (houseCategory) {
      const listingAttributes =
        (listing.attributes &&
          Object.fromEntries(listing.attributes.map(({ key, value, unit }) => [key, { value, unit }]))) ??
        {};

      listItem.numberOfBedrooms = listingAttributes[HOUSE_NUMBER_OF_BEDROOMS_KEY]?.value;
      listItem.numberOfRooms = listingAttributes[HOUSE_NUMBER_OF_ROOMS_KEY]?.value;
      listItem.floorSize = listingAttributes[HOUSE_SQUARE_METERS_KEY]?.value;
      listItem.accommodationCategory = listingAttributes[HOUSE_TYPE_KEY]?.value;
      listItem.hasEnergyEfficiencyCategory = energySchemaMapping[listingAttributes[HOUSE_ENERGY_LABEL_KEY]?.value];
      listItem.itemCondition = conditionSchemaMapping[listingAttributes[CONDITION]?.value];

      if (listingAttributes[HOUSE_CITY_KEY]?.value || listingAttributes[HOUSE_REGION_KEY]?.value) {
        listItem.address = {
          '@type': 'PostalAddress',
          addressLocality: listingAttributes[HOUSE_CITY_KEY]?.value,
          addressRegion: listingAttributes[HOUSE_REGION_KEY]?.value,
        };
      }
    }

    itemList.push({
      '@type': ['ListItem'],
      position: index + 1,
      item: listItem,
    });
  });

  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: itemList,
  };
};

export default getItemListLdSchema;
