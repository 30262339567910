import { request } from '@lrp/api-client';
import { searchRequestToQueryObject } from '@/client/actions/searchRequestToQueryString';

const createSavedSearch = (xsrfToken: string, searchRequest: TSearchRequest) =>
  request({
    uri: '/lrp/api/saved-searches',
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'x-mp-xsrf': xsrfToken, 'Content-Type': 'application/json' },
    body: JSON.stringify(searchRequestToQueryObject(searchRequest)),
  });

export default createSavedSearch;
