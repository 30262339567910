import linkGeneratorHelper, { generateLinkObject } from '@/utils/links/linkGeneratorHelper';
import { createLinks, shouldMaskLinkFromCrawler } from '@/utils/links/linkHelpers';

type BreadcrumbItem = {
  text?: string;
  link: string;
  shouldMask?: boolean;
};

type Breadcrumbs = {
  items: BreadcrumbItem[];
  resultCount: number;
};

type LinkOptions = {
  searchRequestObject: Partial<TSearchRequest>;
};

type BreadCrumbInfo = {
  href: string;
  shouldMask: boolean;
};

const generateBreadCrumbInfo = (linkOptions: LinkOptions): BreadCrumbInfo => {
  const linkObject = generateLinkObject(linkOptions);
  const href = createLinks(linkObject);
  const shouldMask = shouldMaskLinkFromCrawler({
    seller: linkObject.seller,
    prioritizedAttributes: linkObject.prioritizedAttributes,
    rangeAttributes: linkObject.rangeAttributes,
  });

  return { href, shouldMask };
};

const formatBreadcrumbs = (
  searchRequestObject: TSearchRequest,
  categoriesById: TCategories,
  seller: TSeller,
  totalCount: number,
): Breadcrumbs => {
  const breadcrumbs: Breadcrumbs = {
    items: [],
    resultCount: totalCount,
  };

  if (seller) {
    breadcrumbs.items.push({
      text: seller.title,
      link: linkGeneratorHelper({
        searchRequestObject: {
          seller,
        },
      }),
    });
  }

  const { l1Category, l2Category } = searchRequestObject.categories;
  if (l1Category) {
    const { href, shouldMask } = generateBreadCrumbInfo({
      searchRequestObject: {
        categories: { l1Category },
        ...(seller && { seller }),
      },
    });
    breadcrumbs.items.push({
      text: categoriesById[l1Category.id].fullName,
      link: href,
      shouldMask,
    });
  }

  if (l2Category) {
    const { href, shouldMask } = generateBreadCrumbInfo({
      searchRequestObject: {
        categories: { l1Category, l2Category },
        ...(seller && { seller }),
      },
    });
    breadcrumbs.items.push({
      text: categoriesById[l2Category.id].fullName,
      link: href,
      shouldMask,
    });
  }

  return breadcrumbs;
};

export default formatBreadcrumbs;
