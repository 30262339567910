import vurlGenerator from '@/HOC/utils/analytics/vurlGenerator';
import attributesGenerator from '@/HOC/utils/analytics/attributesGenerator';
import dataLayerKeys from '@/server/constants/dataLayer';
import { SORT_BY, SORT_ORDER } from '@/constants/sorting';

export default function getDataLayerObject({
  facets,
  locale,
  attributeHierarchy,
  totalResultCount,
  searchRequest,
  searchCategory,
}) {
  const { categories, searchQuery, seller, attributesById, pagination, sortOptions, distance, attributeRanges } =
    searchRequest;

  const viewKind = searchRequest.viewOptions.kind === 'list-view' ? dataLayerKeys.PAGE_KEY : dataLayerKeys.PAGE_KEY_GV;

  const { postcode, distanceMeters } = distance;
  const pageTypeSRP = searchQuery ? dataLayerKeys.TYPES.SEARCH : dataLayerKeys.TYPES.BROWSE;
  const pageType = seller ? dataLayerKeys.TYPES.SELLER : pageTypeSRP;
  const priceObject = attributeRanges.find((attr) => attr.attributeKey === 'PriceCents');
  // dominant category is only set on L0 Search
  const dominantCategory =
    searchCategory === 0 &&
    searchQuery?.length > 0 &&
    facets.find(({ key }) => key === 'RelevantCategories')?.categories.find(({ dominant }) => dominant)?.id;

  return {
    categories,
    location: {
      postcode,
      distance: distanceMeters,
    },
    vurl: vurlGenerator({
      categories,
      query: searchQuery,
      isSOI: !!seller,
      isZRP: totalResultCount === 0,
      hasAttributes: attributesById.length > 0,
    }),
    pageType,
    attributes: attributesGenerator(attributeHierarchy, attributeRanges, categories),
    query: searchQuery,
    pageNumber: Math.ceil(pagination.offset / pagination.limit) + 1,
    pageSize: pagination.limit,
    sortOptions: {
      sortOrder: sortOptions.sortOrder || SORT_ORDER.DECREASING,
      sortBy: sortOptions.sortBy || SORT_BY.OPTIMIZED,
    },
    totalResultCount,
    ...(priceObject && { priceRange: { from: priceObject.from, to: priceObject.to } }),
    viewKind,
    locale,
    dominantCategory,
  };
}
