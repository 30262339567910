import enrichLocaleAltWithUrl from '@/client/utils/enrichLocaleAltWithUrl';

export const getSearchCategoryName = (categoriesById, searchCategory) =>
  categoriesById?.[searchCategory]?.name || categoriesById?.[searchCategory]?.fullName || '';

export const getPageType = (hasResults) => (hasResults ? 'LRP' : 'ZRP');

export const enrichLocaleAlt = ({ localeAlt, alternativeLocales, attributeHierarchy, searchRequestObject }) =>
  localeAlt &&
  enrichLocaleAltWithUrl({
    localeAlt,
    alternativeLocales,
    attributeHierarchy,
    searchRequestObject,
  });
