export default {
  SUGGESTED_SEARCHES: {
    EVENT_ACTION: {
      POPULAR_SEARCHES: 'PopularSearch',
      RELATED_SEARCHES: 'RelatedSearch',
      SIMILAR_AI_SEARCHES: 'SimilarAiSearch',
      POPULAR_BRANDS: 'PopularBrands',
    },
    EVENT_LABEL: 'Click',
  },
  FILTER_CHIPS: {
    EVENT_ACTION: {
      CHIP_ITEM: 'RefineSearchAttributesFromChips',
      REMOVE_ALL: 'DeleteAllSearchAttributesFromChips',
    },
    EVENT_LABEL: '708',
  },
  FILTER: {
    EVENT_ACTION: 'Filter',
    EVENT_LABEL: {
      SINGLE_CATEGORY: 'SingleCategory',
      PARENT_CATEGORY: 'ParentCategory',
      DISTANCE: 'Distance',
    },
  },
  QUICK_FILTER: {
    EVENT_ACTION: 'QuickFilter',
  },
  FOLLOW_SELLER: {
    EVENT_ACTION: {
      BEGIN: 'FollowUserBegin',
      SUCCESS: 'FollowUserSuccess',
    },
  },
  UNFOLLOW_SELLER: {
    EVENT_ACTION: {
      BEGIN: 'UnfollowUserBegin',
      SUCCESS: 'UnfollowUserSuccess',
    },
  },
  SAVE_SEARCH: {
    EVENT_ACTION: {
      BEGIN: 'SaveSearchBegin',
      SUCCESS: 'SaveSearchSuccess',
    },
    EVENT_LABEL: '{CAT_ID}:{QUERY}',
  },
  SELLER_WEBSITE_URL: {
    EVENT_ACTION: 'R2SExternalBegin',
    EVENT_LABEL: 'Admarkt',
  },
  CES: {
    EVENT_ACTION: 'CESSurveyDisplayed',
  },
  AUTO_COMPLETE: {
    EVENT_ACTION: 'AutoComplete',
    EVENT_LABEL: {
      RECENT: 'SuggestionClicked-Recent',
      SUGGESTION: 'SuggestionClicked',
      SUGGESTION_CATEGORY: 'SuggestionClicked-Category',
    },
  },
  LISTING_CAROUSEL: {
    EVENT_ACTION: {
      CHANGE: 'ListingCarouselItemChanged',
    },
  },
  SEARCH_OPTIONS: {
    EVENT_ACTION: 'ToggleSearchInSellerListings',
  },
  NDFC_RANKING_TEXT: {
    EVENT_LABEL: 'NdfcGenericRankingText',
    EVENT_ACTION: {
      DISPLAYED: 'Displayed',
      CLOSED: 'Closed',
    },
  },
  FAVORITE: {
    EVENT_ACTION: {
      ADD_BEGIN: 'WatchlistAddBegin',
      ADD_SUCCESS: 'WatchlistAddSuccess',
      REMOVE_BEGIN: 'WatchlistRemoveBegin',
      REMOVE_SUCCESS: 'WatchlistRemoveSuccess',
    },
    EVENT_LABEL: 'SRP',
  },
  EXTENDED_CONTENT: {
    EVENT_ACTION: 'Click',
    EVENT_LABEL: 'details_button',
  },
  SMART_BANNER: {
    EVENT_ACTION: {
      DISPLAY: 'Display',
    },
    EVENT_LABEL: 'Experimental_Smart_Banner',
  },
};
