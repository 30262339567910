import React from 'react';

import { Trans } from '@ecg-marktplaats/js-react-i18n';
import { Icon } from '@hz-design-system/web-ui';

import trackEvent from '@/client/utils/analytics/trackEvent';
import trackingKeys from '@/client/constants/analytics/trackingKeys';

type TListingDetailsButton = {
  isDetailsOpen: boolean;
  setIsDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const ListingDetailsButton = ({ isDetailsOpen, setIsDetailsOpen }: TListingDetailsButton) => {
  const handleDetailsClick = (e) => {
    setIsDetailsOpen(!isDetailsOpen);
    trackEvent({
      eventAction: trackingKeys.EXTENDED_CONTENT.EVENT_ACTION,
      eventLabel: trackingKeys.EXTENDED_CONTENT.EVENT_LABEL,
    });
    e.preventDefault();
  };

  return (
    <div className="hz-Listing-details-button">
      <div
        tabIndex={0}
        onClick={(e) => handleDetailsClick(e)}
        onKeyDown={(e) => e.key === 'Enter' && handleDetailsClick(e)}
      >
        <Trans tagName="span" i18nKey="listings.details" />
        <Icon name={`${isDetailsOpen ? 'ArrowUp' : 'ArrowDown'}`} size="m" color="text-secondary" />
      </div>
    </div>
  );
};

export default ListingDetailsButton;
