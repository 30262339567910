'use client';

import React, { ReactNode, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import trackEvent from '@/client/utils/analytics/trackEvent';
import trackingKeys from '@/client/constants/analytics/trackingKeys';

const MaskedLink = dynamic(() => import('@hz-design-system/web-ui').then((mod) => mod.MaskedLink), { ssr: false });

interface ListingSellerExternalLinkProps {
  url?: string;
  children: ReactNode;
  className?: string;
  noopLink?: boolean;
}

const sellerWebsiteUrlClickHandler = () => () => {
  trackEvent({
    eventAction: trackingKeys.SELLER_WEBSITE_URL.EVENT_ACTION,
    eventLabel: trackingKeys.SELLER_WEBSITE_URL.EVENT_LABEL,
  });
};

const ListingSellerExternalLink: React.FC<ListingSellerExternalLinkProps> = ({
  url,
  children,
  className = '',
  noopLink = false,
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  if (noopLink || !url) {
    return <>{children}</>;
  }

  return (
    <MaskedLink
      onClick={sellerWebsiteUrlClickHandler()}
      href={url}
      target="_blank"
      rel="noopener noreferrer nofollow"
      className={`hz-Listing-sellerCoverLink ${className}`}
    >
      {children}
    </MaskedLink>
  );
};

export default ListingSellerExternalLink;
