import { getURlWithoutJPG } from '@ecg-marktplaats/media-util-js-lib';

import categories from '@/constants/categories';

const NUMBER_OF_CENTS_IN_A_EURO = 100;
const MIN_PRICE_EUROS = 500;
const MAX_PRICE_EUROS = 500000;

const MIN_PRICE_CENTS = MIN_PRICE_EUROS * NUMBER_OF_CENTS_IN_A_EURO;
const MAX_PRICE_CENTS = MAX_PRICE_EUROS * NUMBER_OF_CENTS_IN_A_EURO;

const priceSortFn = (a, b) => (a.priceInfo.priceCents > b.priceInfo.priceCents ? 1 : -1);

const isAdMarkt = (itemId) => itemId[0] === 'a';

export const getFormattedPrice = (priceCents) => Math.round(priceCents / NUMBER_OF_CENTS_IN_A_EURO);

const getProductLdSchema = (l1Category, l2Category, searchRequestAndResponse) => {
  const { attributeHierarchy, listings, originalQuery, searchRequest } = searchRequestAndResponse;

  const searchedL1CategoryId = searchRequest?.categories?.l1Category?.id;

  const name = originalQuery || l2Category?.fullName || l1Category?.fullName;

  const modelFromAttributeHierarchy = attributeHierarchy?.model?.[0];
  const model = modelFromAttributeHierarchy?.attributeValueId ? modelFromAttributeHierarchy.attributeValueLabel : '';

  const carsOnlyFilter = (listing) =>
    searchedL1CategoryId !== categories.CARS ||
    (listing.priceInfo.priceCents >= MIN_PRICE_CENTS && listing.priceInfo.priceCents <= MAX_PRICE_CENTS);

  const filteredListings = listings.filter(carsOnlyFilter);

  if (!filteredListings.length) {
    return null;
  }

  const firstListingWithImage = filteredListings.find((l) => !isAdMarkt(l.itemId) && Boolean(l.pictures?.length));
  const firstImageUrl = firstListingWithImage ? getURlWithoutJPG(firstListingWithImage?.pictures[0].mediumUrl) : '';

  const sortedListings = filteredListings.filter((l) => !isNaN(l.priceInfo.priceCents)).sort(priceSortFn);
  const lowPrice = getFormattedPrice(sortedListings[0].priceInfo.priceCents);
  const highPrice = getFormattedPrice(sortedListings[sortedListings.length - 1].priceInfo.priceCents);

  const type = searchedL1CategoryId === categories.CARS ? 'Vehicle' : 'Product';

  return {
    '@context': 'https://schema.org',
    '@type': type,
    name: name,
    ...(searchedL1CategoryId === categories.CARS && {
      model: model,
    }),
    image: firstImageUrl,
    brand: { '@type': 'Thing', name: '' },
    offers: {
      '@type': 'AggregateOffer',
      lowPrice: lowPrice < 1 ? 1 : lowPrice,
      highPrice: highPrice,
      priceCurrency: 'EUR',
      availability: 'In Stock',
    },
  };
};

export default getProductLdSchema;
