import get from 'lodash.get';
import createBannerDataConfig from '@/client/utils/banners/createBannerDataConfig';
import { BANNER_PAGE_KEYS, BANNER_PARAMETERS } from '@/constants/banners';
import experiments from '@/utils/labs/experiments';
import createAttributesForBanners from '@/common/helpers/banners/createAttributesForBanners';
import getVurlForGrafana from '@/client/utils/banners/getVurlForGrafana';
import getBannerPositions from '@/client/utils/banners/getBannerPositions';
import getCategoryMetaData from '@/client/utils/getCategoryMetaData';

const createBannerConfig = async ({
  xsrfToken,
  adblockerFile,
  attributes,
  bannerProperties,
  fingerPrint,
  hostname,
  labsConfig,
  loggedInUser,
  mainScriptUrl,
  postcode,
  query,
  rsiSegments,
  searchRequest,
  seller,
  tenantContext,
  useSourcePointCMP,
  dominantCategory,
  view,
  consent,
}) => {
  const categoryInformation = getCategoryMetaData(searchRequest);
  const experimentsConfig = experiments({ labsConfig });

  let pageKey = query ? BANNER_PAGE_KEYS.SRP : BANNER_PAGE_KEYS.LRP;
  pageKey = seller ? BANNER_PAGE_KEYS.SOI : pageKey;

  const positions = getBannerPositions(view, seller);

  const bannerDataConfig = await createBannerDataConfig({
    xsrfToken,
    adblockerFile,
    bannerProperties,
    categoryInformation,
    experiments: experimentsConfig,
    extraBannerParameters: {
      ...createAttributesForBanners(attributes),
      [BANNER_PARAMETERS.POSTCODE]: postcode,
      [BANNER_PARAMETERS.KEYWORD]: query,
      [BANNER_PARAMETERS.SELLER_ID]: get(seller, 'id'),
    },
    fingerPrint,
    hostname,
    labsConfig,
    loggedInUser,
    mainScriptUrl,
    pageKey,
    positions,
    rsiSegs: rsiSegments,
    tenantContext,
    useSourcePointCMP,
    vurl: getVurlForGrafana(query, seller),
    dominantCategory,
    consent,
  });

  return bannerDataConfig;
};

export default createBannerConfig;
