import React from 'react';

interface BannerProps {
  position: string;
  type: string;
  loading?: boolean;
  bannerForMobile?: boolean;
}

const Banner: React.FC<BannerProps> = ({ position, type, loading, bannerForMobile }) => {
  const loadingClassName = loading ? 'loading' : '';

  return (
    <div suppressHydrationWarning={true} className={`hz-Banner hz-Banner--${type}`}>
      <div
        suppressHydrationWarning={true}
        id={`banner-${position}`}
        className={`${loadingClassName} ${bannerForMobile ? 'mobileOnly' : ''}`}
      />
    </div>
  );
};

export default Banner;
