const lazyLoadScript = (() => {
  const lazyScriptsList = [];

  const addScript = (attribute, callback) => {
    const script = document.createElement('script');
    Object.keys(attribute).forEach((attr) => {
      script.setAttribute(attr, attribute[attr] ? attribute[attr] : null);
    });
    script.onload = callback;
    document.body.appendChild(script);
  };

  return {
    load(attribute, callback) {
      if (lazyScriptsList.includes(attribute.src)) {
        return;
      }
      addScript(attribute, callback);
      lazyScriptsList.push(attribute.src);
    },
  };
})();

export default lazyLoadScript;
