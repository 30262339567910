import React, { ReactNode } from 'react';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';

interface ListingOpvalStickerProps {
  children: ReactNode;
  isGalleryView?: boolean;
}

const ListingOpvalSticker: React.FC<ListingOpvalStickerProps> = ({ children, isGalleryView = false }) => (
  <div
    className={getSuitCSSClasses({
      namespace: 'hz',
      name: 'Listing-Opvalsticker-wrapper',
      modifiers: isGalleryView ? ['gallery'] : ['list-item'],
    })}
    data-testid="listing-opval-sticker"
  >
    <span className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-Opvalsticker' })}>{children}</span>
  </div>
);

export default ListingOpvalSticker;
