import React, { memo, useContext, useState, useRef } from 'react';
import isEqual from 'lodash.isequal';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { Checkbox } from '@hz-design-system/web-ui';

import { ListingProps } from '@/client/types/Listings';
import experiments from '@/utils/labs/experiments';
import VIEW_OPTIONS from '@/constants/viewOptions';
import VERTICALS from '@/constants/verticals';
import { MAX_HIGHLIGHTS_PER_AD } from '@/client/constants/listings';
import trackingKeys from '@/client/constants/analytics/trackingKeys';
import distanceKmFormatter from '@/client/utils/formatters/distanceKmFormatter';
import priceFormatter from '@/client/utils/formatters/priceFormatter';
import trustIndicatorFormatter from '@/client/utils/formatters/trustIndicatorFormatter';
import { sellerUrlGenerator } from '@/utils/links/linkHelpers';
import linkGeneratorHelper from '@/utils/links/linkGeneratorHelper';
import trackEvent from '@/client/utils/analytics/trackEvent';
import toggleFavorite from '@/client/actions/favorites/toggleFavorite';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import ListingGalleryViewContent from '@/client/components/Listings/molecules/ListingGalleryViewContent';
import ListingGalleryViewBottomBlockContent from '@/client/components/Listings/molecules/ListingGalleryViewBottomBlockContent';
import ListingListViewContentCars from '@/client/components/Listings/molecules/ListingListViewContentCars';
import ListingListViewContent from '@/client/components/Listings/molecules/ListingListViewContent';
import locationFormatter from '@/client/utils/formatters/locationFormatter';

const trackGaEventForBegin = (isFavorite: boolean) => {
  trackEvent({
    eventAction: isFavorite
      ? trackingKeys.FAVORITE.EVENT_ACTION.REMOVE_BEGIN
      : trackingKeys.FAVORITE.EVENT_ACTION.ADD_BEGIN,
    eventLabel: trackingKeys.FAVORITE.EVENT_LABEL,
  });
};

const trackGaEventForSuccess = (isFavorite: boolean) => {
  trackEvent({
    eventAction: isFavorite
      ? trackingKeys.FAVORITE.EVENT_ACTION.REMOVE_SUCCESS
      : trackingKeys.FAVORITE.EVENT_ACTION.ADD_SUCCESS,
    eventLabel: trackingKeys.FAVORITE.EVENT_LABEL,
  });
};

const getElementProps = (props: ListingProps, i18n: any) => {
  const { t } = i18n;
  const { priceInfo, date, location, trustIndicators, sellerInformation, verticals = [] } = props;
  const sellerInfo = {
    id: sellerInformation.sellerId,
    name: sellerInformation.sellerName,
  };

  priceInfo.suppressZeroCents = verticals.includes(VERTICALS.CARS) || verticals.includes(VERTICALS.AUTOMOTIVE);

  return {
    ...props,
    location: {
      label: locationFormatter(location, i18n),
      distance: distanceKmFormatter(location.distanceMeters),
    },
    price: t(priceFormatter(priceInfo), priceInfo),
    date: date,
    sellerInformation: {
      ...sellerInformation,
      soiUrl: sellerUrlGenerator(sellerInfo),
    },
    ...(trustIndicators && {
      trustIndicators: trustIndicators.slice(0, MAX_HIGHLIGHTS_PER_AD).map((ti) => ({
        ...ti,
        value: trustIndicatorFormatter(ti, i18n),
      })),
    }),
  };
};

const Listing: React.FC<ListingProps> = ({
  selectAction,
  isSelected = false,
  isSelectable = false,
  isFavorited = false,
  isBottomBlock = false,
  variant = '',
  vertical = '',
  otherSellerListings,
  searchRequestObject,
  thinContent,
  isSOIPage,
  index,
  ...props
}) => {
  const i18n = useI18nContext();
  const { t } = i18n;
  const {
    xsrfToken,
    labsConfig,
    userDetails: { isLoggedIn },
  } = useContext(EnvironmentContext);
  const { isCarsExtendedAdDetailsEnabled } = experiments({ labsConfig });
  const l1Category = searchRequestObject?.categories?.l1Category?.id;
  const categories = searchRequestObject?.categories;
  const isCarsVariant = vertical === VERTICALS.CARS;
  const verticalVariant = vertical ? `-${vertical}` : '';
  const { kind, sellerInformation, itemId, extendedAttributes, categorySpecificDescription, priorityProduct } = props;
  const elementProps = getElementProps(props as ListingProps, i18n);
  const [isFavorite, setIsFavorite] = useState<boolean>(isFavorited);
  const viewKind = kind === VIEW_OPTIONS.LIST_VIEW ? 'list-item' : 'gallery-item';
  const isListItem = viewKind === 'list-item';
  const handleSelectAction = () => selectAction && selectAction(itemId, !isSelected);
  const loadingType = isBottomBlock || index > 0 ? 'lazy' : 'eager';
  const decodingType = isBottomBlock || index > 0 ? 'async' : undefined;
  const shouldDisplayExtendedContent =
    (!isCarsVariant || isCarsExtendedAdDetailsEnabled) &&
    !isSOIPage &&
    (!!extendedAttributes?.length || !!categorySpecificDescription) &&
    !isBottomBlock;
  const closeToast = useRef<any>();
  let otherSellerUrl: string | undefined;
  if (isListItem && otherSellerListings && otherSellerListings.totalSellerListingsResults > 1) {
    otherSellerUrl = linkGeneratorHelper({
      searchRequestObject: {
        ...searchRequestObject,
        seller: {
          id: sellerInformation.sellerId,
          name: sellerInformation.sellerName,
        },
      },
    });
  }

  const onFavorite = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!isLoggedIn) {
      if (typeof window === 'undefined') return;
      window.location.assign(`/account/login.html?target=${encodeURIComponent(window.location.href)}`);
      return;
    }

    setIsFavorite(!isFavorite);
    trackGaEventForBegin(isFavorite);

    if (closeToast.current) {
      closeToast.current();
    }

    const [response, { default: showToastNotification, dismissToast }] = await Promise.all([
      toggleFavorite({ itemId, xsrfToken, favorite: !isFavorite }),
      import(/* webpackChunkName: "toastNotification" */ '../../Toast/ToastNotification'),
    ]);

    closeToast.current = dismissToast;

    if (isFavorite !== response.isFavorite) {
      trackGaEventForSuccess(isFavorite);

      showToastNotification({
        type: 'success',
        description: t(
          isFavorite ? 'listing.favourite.notifications.unfavoriting' : 'listing.favourite.notifications.favoriting',
        ),
      });
    } else {
      showToastNotification({
        type: 'error',
        description: t('page.error.error_global_ajax'),
      });
    }

    // actual update
    setIsFavorite(response.isFavorite);
  };

  return (
    <li
      className={getSuitCSSClasses({
        namespace: 'hz',
        name: 'Listing',
        modifiers: [`${viewKind}${verticalVariant}`, variant],
      })}
    >
      {isSelectable && (
        <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-select-container' })}>
          <Checkbox value="true" isChecked={isSelected} onChange={handleSelectAction} labelText={''} />
        </div>
      )}

      {isListItem && !isCarsVariant && (
        <ListingListViewContent
          {...elementProps}
          attributes={elementProps.attributes || []}
          extendedAttributes={elementProps.extendedAttributes || []}
          isBottomBlock={isBottomBlock}
          loadingType={loadingType}
          decodingType={decodingType}
          l1Category={l1Category}
          categories={categories}
          isFavorite={isFavorite}
          onFavorite={onFavorite}
          thinContent={thinContent}
          otherSellerUrl={otherSellerUrl}
          otherSellerListings={otherSellerListings}
          shouldDisplayExtendedContent={shouldDisplayExtendedContent}
        />
      )}

      {isListItem && isCarsVariant && (
        <ListingListViewContentCars
          {...elementProps}
          attributes={elementProps.attributes || []}
          extendedAttributes={elementProps.extendedAttributes || []}
          isBottomBlock={isBottomBlock}
          loadingType={loadingType}
          decodingType={decodingType}
          isFavorite={isFavorite}
          onFavorite={onFavorite}
          thinContent={thinContent}
          priceType={props.priceInfo.priceType}
          categories={categories}
          shouldDisplayExtendedContent={shouldDisplayExtendedContent}
        />
      )}

      {!isListItem && isBottomBlock && (
        <ListingGalleryViewBottomBlockContent
          title={elementProps.title}
          price={elementProps.price}
          pictures={elementProps.pictures}
          sellerInformation={elementProps.sellerInformation}
          loadingType={loadingType}
          decodingType={decodingType}
          vipUrl={elementProps.vipUrl}
          trackingData={elementProps.trackingData}
          correlationId={elementProps.correlationId}
          pageLocation={elementProps.pageLocation}
          thinContent={thinContent}
          categories={categories}
          extendedAttributes={props.extendedAttributes}
        />
      )}

      {!isListItem && !isBottomBlock && (
        <ListingGalleryViewContent
          {...elementProps}
          location={elementProps.location}
          title={elementProps.title}
          subtitle={elementProps.subtitle}
          price={elementProps.price}
          sellerInformation={elementProps.sellerInformation}
          date={elementProps.date}
          pictures={elementProps.pictures}
          vipUrl={elementProps.vipUrl}
          trackingData={elementProps.trackingData}
          correlationId={elementProps.correlationId}
          pageLocation={elementProps.pageLocation}
          loadingType={loadingType}
          decodingType={decodingType}
          opvalStickerText={elementProps.opvalStickerText}
          l1Category={l1Category}
          priorityProduct={priorityProduct}
          isFavorite={isFavorite}
          onFavorite={onFavorite}
          thinContent={thinContent}
          categories={categories}
          extendedAttributes={props.extendedAttributes}
        />
      )}
    </li>
  );
};

export default memo(Listing, (prevProps, nextProps) => isEqual(prevProps, nextProps));
