/* eslint-disable no-magic-numbers */
const millisInDay: number = 24 * 60 * 60 * 1000;

export const getCookie = (name: string): string | null => {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
};

export const setCookie = (name: string, value: string, days?: number): void => {
  document.cookie = `${name}=${value};path=/;${days ? `expires=${new Date(Date.now() + millisInDay * days).toUTCString()};` : ''}`;
};
