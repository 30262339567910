export const SORT_BY = {
  OPTIMIZED: 'OPTIMIZED',
  SORT_INDEX: 'SORT_INDEX',
  ATTRIBUTE: 'ATTRIBUTE',
  PRICE: 'PRICE',
  LOCATION: 'LOCATION',
};

export const SORT_ORDER = {
  DECREASING: 'DECREASING',
  INCREASING: 'INCREASING',
};
