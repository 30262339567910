import React from 'react';
import { PopOverContent, PopOverWrapper, PopOverTrigger } from '@hz-design-system/web-ui';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

type TInfoTooltipProps = {
  helpTextKey: string;
  onCloseTooltip: () => void;
};

const InfoTooltip = ({ helpTextKey, onCloseTooltip }: TInfoTooltipProps) => (
  <PopOverWrapper className="infoTooltip" as="span" placement="bottom" openOnLoad>
    <PopOverTrigger>
      <span></span>
    </PopOverTrigger>
    <PopOverContent onClose={onCloseTooltip}>
      <Trans i18nKey={helpTextKey} br={Trans.safeString('<br>')} />
    </PopOverContent>
  </PopOverWrapper>
);

export default InfoTooltip;
