import React, { FC } from 'react';

interface Image {
  title: string;
  alt: string;
  imageSource: string;
}

interface ListingImageProps {
  image: Image;
  loadingType?: 'eager' | 'lazy';
  decodingType?: 'sync' | 'async' | 'auto';
}

const ListingImage: FC<ListingImageProps> = ({ image, loadingType, decodingType }) => {
  return (
    <img
      suppressHydrationWarning
      title={image.title}
      alt={image.alt}
      data-src={image.imageSource}
      src={image.imageSource}
      loading={loadingType}
      decoding={decodingType}
    />
  );
};

export default ListingImage;
