import { defaults } from '@/constants/attributes/nonStandards';

/**
 * generate attributes for data layer
 * @param {object} attributeHierarchy
 * @param {Array} attributeRanges
 * @param {object} categories
 * @returns {object|string}
 */
const attributesGenerator = (attributeHierarchy = {}, attributeRanges = [], categories = {}) => {
  const hasCategories = Object.keys(categories).length > 0;

  if (Object.values(attributeHierarchy).length === 0 && attributeRanges.length === 0 && !hasCategories) {
    return '';
  }

  const attributes = {};

  Object.keys(attributeHierarchy)
    .filter((key) => !defaults.attributes.includes(attributeHierarchy[key]?.[0].attributeValueKey))
    .forEach((key) => {
      attributes[key] = [];
      attributeHierarchy[key].forEach((attr) => {
        attributes[key].push(attr.attributeValueKey);
      });
    });

  attributeRanges.forEach(({ attributeKey, ...range }) => {
    attributes[attributeKey] = range;
  });

  if (categories.l2Category) {
    attributes.l2Category = categories.l2Category;
  }

  return attributes;
};

export default attributesGenerator;
