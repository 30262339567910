import React, { useContext } from 'react';
import { Link, MaskedLink } from '@hz-design-system/web-ui';

import pushToRouter from '@/client/utils/pushToRouter';
import { generateLinkObject } from '@/utils/links/linkGeneratorHelper';
import trackEvent from '@/client/utils/analytics/trackEvent';
import getClientPageQueryObject from '@/client/utils/getClientPageQueryObject';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import experiments from '@/utils/labs/experiments';
import { createLinks, shouldMaskLinkFromCrawler } from '@/utils/links/linkHelpers';
import CATEGORIES_L1 from '@/constants/categories';

interface LinkGeneratorProps {
  eventAction?: string;
  eventLabel?: string;
  eventMetric?: string;
  className?: string;
  children: React.ReactNode;
  rangeAttributes?: any[];
  isSingleSelect?: boolean;
  isSelected?: boolean;
  attributes?: { [key: string]: any };
  page?: number;
  categories?: {
    l1Category?: TCategory;
    l2Category?: TCategory;
  };
  distance?: {
    postcode?: string;
    distance?: number;
    distanceMeters?: number;
  };
  query?: string;
  pageSize?: number;
  removeAllFilters?: boolean;
  removeAllAttributes?: boolean;
  searchRequestObject?: TSearchRequest;
  allowSavedSearch?: boolean;
  bypassSpellingSuggestion?: boolean;
  isSuggestedSearch?: boolean;
  preventScroll?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const LinkGenerator = ({
  eventAction,
  eventLabel,
  eventMetric,
  className,
  children,
  searchRequestObject,
  rangeAttributes,
  categories,
  query = '',
  attributes = {},
  page = 1,
  pageSize,
  isSingleSelect,
  isSelected,
  removeAllFilters = false,
  removeAllAttributes = false,
  bypassSpellingSuggestion,
  allowSavedSearch = false,
  distance,
  isSuggestedSearch = false,
  preventScroll = false,
  onClick: onClickCallback,
}: LinkGeneratorProps) => {
  const { labsConfig } = useContext(EnvironmentContext);
  const { isFetchResultsOnceEnabled, isFetchResultsOnceAllCatEnabled } = experiments({ labsConfig });
  const withAllAttributes =
    isFetchResultsOnceAllCatEnabled ||
    (isFetchResultsOnceEnabled && searchRequestObject?.categories?.l1Category?.id === CATEGORIES_L1.DAMES_KLEREN);

  const linkOptions = {
    searchRequestObject,
    ...(query && { query }),
    newAttribute: attributes,
    isSingleSelect: !!isSingleSelect,
    isSelected: !!isSelected,
    rangeAttributes,
    categories,
    removeAllFilters,
    removeAllAttributes,
    page,
    pageSize,
    bypassSpellingSuggestion,
    allowSavedSearch,
    distance,
    isSuggestedSearch,
    withAllAttributes: false,
  };

  const linkObject = generateLinkObject(linkOptions);
  let linkObjectWithAllAttributes;
  let href: string;
  let pageQueryObj: any;

  if (withAllAttributes) {
    linkOptions.withAllAttributes = true;
    linkObjectWithAllAttributes = generateLinkObject(linkOptions);
    href = createLinks(linkObjectWithAllAttributes);
  } else {
    href = createLinks(linkObject);
  }

  const shouldMask = shouldMaskLinkFromCrawler({
    seller: linkObject.seller,
    prioritizedAttributes: linkObject.prioritizedAttributes,
    rangeAttributes: linkObject.rangeAttributes,
  });

  const LinkComponent = shouldMask ? MaskedLink : Link;

  if (withAllAttributes) {
    pageQueryObj = getClientPageQueryObject(linkObjectWithAllAttributes);
  } else {
    pageQueryObj = getClientPageQueryObject(linkObject);
  }

  const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    trackEvent({ eventAction, eventLabel, eventMetric });

    if (onClickCallback) {
      onClickCallback(event);
    }

    if (preventScroll) {
      if (typeof window !== 'undefined') {
        (window as any).preventScroll = true;
      }
    }

    if (href !== '/') {
      event.preventDefault();
      pushToRouter({ query: pageQueryObj, href });
    }
  };

  return (
    <LinkComponent onClick={onClickHandler} href={href} className={className}>
      {children}
    </LinkComponent>
  );
};

export default LinkGenerator;
