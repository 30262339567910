import { BANNER_PAGE_VURLS } from '@/constants/banners';

/**
 * This function gets the correct vurl identifier for tracking requests
 * in grafana
 */
const getVurlForGrafana = (query?: string, seller?: number) => {
  if (seller) {
    return BANNER_PAGE_VURLS.SOI;
  }

  if (query) {
    return BANNER_PAGE_VURLS.SRP;
  }

  return BANNER_PAGE_VURLS.LRP;
};

export default getVurlForGrafana;
