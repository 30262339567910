import React, { useContext } from 'react';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

import trackEvent from '@/client/utils/analytics/trackEvent';
import trackingKeys from '@/client/constants/analytics/trackingKeys';
import createSavedSearchAction from '@/client/actions/savedSearches/createSavedSearch';
import SaveSearchButton from '@/client/components/SaveSearch/SaveSearchButton';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import { categoryIdFromRequest } from '@/utils/categories';

interface SaveSearchContainerProps {
  pushNotification: (type: string, message: React.ReactElement) => void;
  searchRequest: TSearchRequest;
  asPrimary?: boolean;
  showLabel?: boolean;
  smallButton?: boolean;
  className?: string;
  setDisabled?: (isDisabled: boolean) => void;
  isDisabled?: boolean;
}

const createSavedSearch = async (xsrfToken, setDisabled, pushNotification, searchRequest) => {
  const eventLabel = `${categoryIdFromRequest(searchRequest)}:${searchRequest.searchQuery}`;
  trackEvent({
    eventAction: trackingKeys.SAVE_SEARCH.EVENT_ACTION.BEGIN,
    eventLabel,
  });

  setDisabled(true);

  try {
    await createSavedSearchAction(xsrfToken, searchRequest);
    const message = <Trans i18nKey="saveSearch.messages.createSuccess" />;
    pushNotification('success', message);
    trackEvent({
      eventAction: trackingKeys.SAVE_SEARCH.EVENT_ACTION.SUCCESS,
      eventLabel,
    });
  } catch (err) {
    setDisabled(false);
    const message = <Trans i18nKey="messages.generic_error" />;
    pushNotification('error', message);
  } finally {
    if (typeof window !== 'undefined') window.scroll(0, 0);
  }
};

const redirectToLoginPage = () =>
  typeof window !== 'undefined' &&
  window.location.assign(`/account/login.html?target=${encodeURIComponent(window.location.href)}`);

const SaveSearchContainer: React.FC<SaveSearchContainerProps> = ({
  pushNotification,
  searchRequest,
  asPrimary = false,
  showLabel = false,
  smallButton = false,
  className,
  setDisabled,
  isDisabled = false,
}) => {
  const {
    xsrfToken,
    userDetails: { isLoggedIn },
  } = useContext(EnvironmentContext);
  const handleClick = () => createSavedSearch(xsrfToken, setDisabled, pushNotification, searchRequest);

  return (
    <div suppressHydrationWarning className="saveSearchControl">
      {showLabel && <Trans tagName="p" i18nKey="saveSearch.messages.invite" />}
      <SaveSearchButton
        onClick={isLoggedIn ? handleClick : redirectToLoginPage}
        disabled={isDisabled}
        asPrimary={asPrimary}
        smallButton={smallButton}
        customClassName={className}
      />
    </div>
  );
};

export default SaveSearchContainer;
