import { getCookie, setCookie } from '@/client/utils/cookies';
import buildEvent from '@/client/utils/audience-targeting/buildEvent';
import { sendTargetingEvent } from '@lrp/api-client';
import COOKIES from '@/constants/cookies';
import { logger } from '@lrp/logger';

type SendEventParams = {
  audienceTargeting: {
    isEnabled: boolean;
    fingerPrint: string;
  };
  user: TUserModel;
  tenantContext: TTenantContext;
  searchRequestAndResponse: TSearchRequestAndResponse;
  xsrfToken: string;
};

const sendEvent = ({
  audienceTargeting: { isEnabled, fingerPrint },
  user,
  tenantContext,
  searchRequestAndResponse,
  xsrfToken,
}: SendEventParams): void => {
  const aamUuid = getCookie(COOKIES.ADOBE_IDENTIFIER);

  if (isEnabled && aamUuid) {
    const {
      attributeHierarchy,
      searchRequest: { distance: { postcode } = {}, searchQuery, attributeRanges, categories, sortOptions } = {},
    } = searchRequestAndResponse;

    const targetingData = buildEvent({
      ...(user.isLoggedIn && { encryptedUserId: user.encryptedId }),
      tenantContext,
      fingerPrint,
      categories,
      postcode,
      query: searchQuery,
      rangeAttributes: attributeRanges || [],
      attributeHierarchy,
      sorting: sortOptions,
      aamUuid,
    });

    sendTargetingEvent(xsrfToken, targetingData)
      .then(({ segments }: { segments: any }) => setCookie(COOKIES.RSI_SEGMENTS, segments))
      .catch((err: any) => logger.warn('sendTargetingEvent', err));
  }
};

export default sendEvent;
