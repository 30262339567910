const getCategoryMetaData = (searchRequest: TSearchRequestResult): TCategoryInformation => {
  const { categories = {} } = searchRequest;
  const categoryMetaData = {
    categoryId: '0',
    categoryName: 'search',
    parentCategoryId: '0',
  };

  if (categories.l2Category) {
    categoryMetaData.categoryId = `${categories.l2Category.id || ''}`;
    categoryMetaData.categoryName = categories.l2Category.fullName;
    categoryMetaData.parentCategoryId = `${categories?.l1Category?.id || ''}`;

    return categoryMetaData;
  }

  if (categories.l1Category) {
    categoryMetaData.categoryId = `${categories.l1Category.id || ''}`;
    categoryMetaData.categoryName = categories.l1Category.fullName;
  }

  return categoryMetaData;
};

export default getCategoryMetaData;
