import getExperimentsByTag from '@/utils/labs/getExperimentsByTag';
import getDominantCategory from '@/utils/getDominantCategory';

const ROOT_CATEGORY_ID = 0;

type TProps = {
  categories: TCategories;
  isZrp: boolean;
  isSrp: boolean;
  view: string;
  labsConfig: any;
  currentPage: number;
  facets: TFacet[] | undefined;
};

/**
 * get channel data for adsense
 * Channel format specification available here https://jira.ets.mpi-internal.com/browse/BNL-4633
 */
const getChannel = ({ categories, isZrp, isSrp, view, labsConfig, currentPage, facets }: TProps): string => {
  // detailed spec available here: https://jira.ets.mpi-internal.com/browse/BNL-5048
  const expInd = '0';
  const os = '_w';

  let pageType = '_brl';
  if (isSrp) {
    pageType = '_srl';
  }
  if (isZrp) {
    pageType = '_zrp';
  }
  pageType += view === 'gallery-view' ? 'gv' : 'lv';

  const l1Cat = categories?.l1Category?.id || ROOT_CATEGORY_ID;
  let l1CatId = `_${l1Cat}`;
  // detailed spec available here: https://jira.corp.ebay.com/browse/BNL-7031
  const isL0 = l1Cat === ROOT_CATEGORY_ID;
  const dominantCategory = getDominantCategory(facets);

  if (isL0 && isSrp && Number.isInteger(dominantCategory)) {
    l1CatId += `.${dominantCategory?.parentId}`;
  }

  const l2CatId = '_x';
  const ads = '_x';
  const page = '_x';
  const geoId = '_x';
  const expName = '_x';
  const expGroup = '_x';
  const pageIndex = `+page${currentPage}`;
  const bnlExperimentValues = getExperimentsByTag({ labsConfig, tag: 'adsense' })
    .map((exp) => `+${exp.key.toLowerCase()}_${exp.group.toLowerCase()}`)
    .join('');

  const channel = [
    expInd,
    os,
    pageType,
    l1CatId,
    l2CatId,
    ads,
    page,
    geoId,
    expName,
    expGroup,
    pageIndex,
    bnlExperimentValues,
  ];
  return channel.join('');
};

export default getChannel;
