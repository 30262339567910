import React, { useState } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';

import InlineInput from '@/client/components/FilterSidebar/atoms/InlineInput';
import { attributeKeys } from '@/constants/attributes/nonStandards';
import AttributeDialog from '@/client/components/FilterSidebar/molecules/AttributeDialog';
import trackEvent from '@/client/utils/analytics/trackEvent';
import HelpTooltip from '@/client/components/FilterSidebar/atoms/HelpTooltip';

type TProps = {
  inputKey: string;
  attributeGroup: TAttributeValue[];
  attributeGroupDialog?: object[];
  label: string;
  searchRequestObject: TSearchRequest;
  totalResults: number;
  collapsable?: boolean;
  isMobile?: boolean;
  maxAttributeValues?: number;
  preventScroll?: boolean;
};

const DEFAULT_MAX_ATTRIBUTE_VALUES = 5;

const AttributeGroupMultiSelectFacet = ({
  label,
  attributeGroup,
  attributeGroupDialog,
  inputKey,
  searchRequestObject,
  totalResults,
  maxAttributeValues = DEFAULT_MAX_ATTRIBUTE_VALUES,
  collapsable,
  isMobile = false,
  preventScroll,
}: TProps) => {
  const { t, tExists } = useI18nContext();
  const helpTextKey = `facets.help.${inputKey}`;
  const withAttributeHelpText = tExists(helpTextKey);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [attributeOptions, setAttributeOptions] = useState(attributeGroup);

  const showHistogramCount = !attributeGroup.some((a) => a.selected);
  // when one language is selected, we disable it
  const hasOneLanguageAttributeSelected = attributeGroup.filter((a) => a.selected).length === 1;
  const oneLangSelected = inputKey === attributeKeys.LANGUAGE && hasOneLanguageAttributeSelected;

  const isTextAttribute = attributeGroup[0].attributeValueId === 0;
  const isCollapsable = isTextAttribute || collapsable;
  const updatedAttributeGroup = isCollapsable
    ? attributeGroup.filter((attribute, i) => attribute.selected || i < maxAttributeValues)
    : attributeGroup;

  if (isTextAttribute && updatedAttributeGroup.length > 0) {
    // Track is text attribute shown
    trackEvent({
      eventAction: 'TextAttributeIsShown',
      eventLabel: 'TextAttribute',
    });
  }

  const handleClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true);

    // Track if text attribute modal open
    trackEvent({
      eventAction: 'TextAttributeModalOpened',
      eventLabel: 'TextAttribute',
    });
  };

  const trackTextAttributeSelection = () => {
    trackEvent({
      eventAction: 'TextAttributeClick',
      eventLabel: 'TextAttribute',
    });
  };

  const title = t(`facets.${inputKey}`, label);

  return (
    <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter' })}>
      <div className="hz-Filter-heading hz-Filter-heading--5">
        {title}
        {withAttributeHelpText && <HelpTooltip helpTextKey={helpTextKey} isMobile={isMobile} />}
      </div>
      {updatedAttributeGroup.map(
        ({ attributeValueKey, attributeValueLabel, histogramCount, attributeValueId, selected }) => {
          // translations first !
          const attrLabel = attributeValueLabel || '';
          const facetLabel = t(`facets.${attributeValueKey}`, attrLabel);
          const attributes = {};
          attributes[inputKey] = {
            attributeValueKey,
            attributeValueId: attributeValueId || null,
            attributeValueLabel: facetLabel,
          };
          const count = histogramCount || (inputKey === 'priceType' ? -1 : 0);

          const disabled = showHistogramCount ? !histogramCount : false;
          let onClickHandler = () => {};
          if (isTextAttribute) {
            onClickHandler = trackTextAttributeSelection;
          }

          return (
            <InlineInput
              key={attributeValueKey}
              count={showHistogramCount ? count : undefined}
              valueKey={attributeValueKey}
              attributeKey={inputKey}
              label={facetLabel}
              type="checkbox"
              checked={selected}
              attributes={attributes}
              disabled={disabled || (oneLangSelected && selected)}
              searchRequestObject={searchRequestObject}
              onClick={onClickHandler}
              preventScroll={preventScroll}
            />
          );
        },
      )}

      {isCollapsable && attributeGroup.length > maxAttributeValues && (
        <span className="hz-TextLink textAttribute-showMore" onClick={handleClick} aria-hidden="true">
          {t('facets.more')}
        </span>
      )}
      {isModalOpen && (
        <AttributeDialog
          title={title}
          attributes={attributeGroupDialog || attributeGroup}
          inputKey={inputKey}
          searchRequestObject={searchRequestObject}
          handleClose={() => setIsModalOpen(false)}
          showHistogramCount={showHistogramCount}
          totalResults={totalResults}
          eventLabel={isTextAttribute ? 'TextAttribute' : 'otherAttribute'}
          isMobile={isMobile}
        />
      )}
    </div>
  );
};

export default AttributeGroupMultiSelectFacet;
