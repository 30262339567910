/**
 * @param fn
 * @returns {Function}
 */
const memoize = (fn) => {
  const cache = new Map();
  return (args) => {
    const n = JSON.stringify(args);
    if (cache.has(n)) {
      return cache.get(n);
    }
    const result = fn(args);
    cache.set(n, result);
    return result;
  };
};

export default memoize;
