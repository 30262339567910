const isInViewport = (elem, offset = 0) => {
  const bounding = elem.getBoundingClientRect();
  return bounding.top >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) - offset;
};

const isTopInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect();
  return bounding.top <= (window.innerHeight || document.documentElement.clientHeight);
};

const isIntersectionObserverSupported = (() => {
  let isSupported;

  const checkIntersectionObserverSupport = () => {
    // Check if all IntersectionObserver and IntersectionObserverEntry
    // features are natively supported.
    if (
      typeof window === 'object' &&
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      // Minimal polyfill for Edge 15's lack of `isIntersecting`
      // See: https://github.com/w3c/IntersectionObserver/issues/211
      if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
        Object.defineProperty(window.IntersectionObserverEntry.prototype, 'isIntersecting', {
          get() {
            return this.intersectionRatio > 0;
          },
        });
      }
      isSupported = true;
    }
  };

  return {
    get() {
      if (typeof isSupported === 'undefined') {
        isSupported = false;
        checkIntersectionObserverSupport();
      }
      return isSupported;
    },
  };
})();

/*
 * An event listener that fires one time only when the viewport is in view
 * @param {HTMLElement} elem - element to listen to
 * @param {Function} eventHandler - event handler to fire when element is in viewport
 * @return {Object} Returns object with remove method for cleaning up event listener
 */
const addIsTopInViewportListener = (elem, eventHandler) => {
  const eventSignature = [
    'scroll',
    (event) => {
      if (isTopInViewport(elem)) {
        if (eventHandler) {
          eventHandler(event);
        }
        window.removeEventListener(...eventSignature);
      }
    },
  ];
  window.addEventListener(...eventSignature);
  return {
    removeEventListener: () => window.removeEventListener(...eventSignature),
  };
};

export { isIntersectionObserverSupported, isInViewport, isTopInViewport, addIsTopInViewportListener };
