export default function mergeAttributeHierarchy(attributeValueList, hierarchy) {
  const newHierarchy = attributeValueList.reduce((acc, attribute) => {
    const { attributeKey, attributeValueLabel, attributeValueKey } = attribute;

    const { attributeValueId, attributeLabel } = hierarchy[attributeKey].find(
      (el) => el.attributeValueKey === attributeValueKey,
    );

    if (!acc[attributeKey]) {
      acc[attributeKey] = [];
    }

    acc[attributeKey].push({
      attributeValueId,
      attributeValueLabel,
      attributeValueKey,
      attributeLabel,
    });

    return acc;
  }, {});

  return {
    ...hierarchy,
    ...newHierarchy,
  };
}
