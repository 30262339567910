import React from 'react';
import { ErrorInpage, InfoInpage, SuccessInpage, WarningInpage } from '@hz-design-system/web-ui';

type TAlertProps = {
  kind: string;
  body: string;
  title?: string;
  className?: string;
};

const Alert = ({ kind, body, title, ...rest }: TAlertProps) => {
  if (kind === 'success') {
    return <SuccessInpage icon="checkmark-circle" description={body} title={title} {...rest} />;
  }
  if (kind === 'info') {
    return <InfoInpage icon="info" description={body} title={title} {...rest} />;
  }
  if (kind === 'tip') {
    return <WarningInpage icon="info" description={body} title={title} {...rest} />;
  }
  return <ErrorInpage icon="alert" description={body} title={title} {...rest} />;
};

export default Alert;
