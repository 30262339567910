import { createQueryPart, createCategoriesPart, createAttributesPart } from '@/utils/links/linkHelpers';

const generateBreadcrumbListItem = ({ name, item, position }) => ({
  '@type': 'ListItem',
  position: Number(position),
  name,
  item,
});

const capitalizeFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1);
const maxAttributesValuesNumber = 2;

const getBreadcrumbs = (req, res) => {
  const {
    l1Category,
    l2Category,
    attributesValuesKeys,
    attributesValuesIds,
    searchQuery,
    i18n: { t },
  } = res.locals;
  const { hostname } = req;
  const tenantName = t('breadcrumbs.platform');
  const baseUrl = `https://${hostname}`;
  let categoriesPart = '';
  let queryPart = '';

  const itemListElement = [
    generateBreadcrumbListItem({
      name: tenantName,
      position: 1,
      item: `${baseUrl}/`,
    }),
  ];

  if (l1Category) {
    const { name } = l1Category;
    categoriesPart = createCategoriesPart({ l1Category });
    const l1Item = generateBreadcrumbListItem({
      name,
      position: itemListElement.length + 1,
      item: `${baseUrl}${categoriesPart}/`,
    });
    itemListElement.push(l1Item);
  }

  if (l2Category) {
    const { name } = l2Category;
    categoriesPart = createCategoriesPart({ l1Category, l2Category });
    const l2Item = generateBreadcrumbListItem({
      name,
      position: itemListElement.length + 1,
      item: `${baseUrl}${categoriesPart}/`,
    });
    itemListElement.push(l2Item);
  }

  if (searchQuery) {
    queryPart = createQueryPart(searchQuery);
    const l2Item = generateBreadcrumbListItem({
      name: searchQuery,
      position: itemListElement.length + 1,
      item: `${baseUrl}${categoriesPart}${queryPart}/`,
    });
    itemListElement.push(l2Item);
  }

  if (attributesValuesKeys) {
    const slicedAttributesValuesKeys = attributesValuesKeys.slice(0, maxAttributesValuesNumber);
    const attributesName = slicedAttributesValuesKeys
      .map((x) => capitalizeFirstLetter(x.replace(/-/g, ' ')))
      .join(' - ');

    const attributes = [];
    slicedAttributesValuesKeys.forEach((attrValue, i) => {
      attributes.push({
        attributeValueLabel: attrValue,
        attributeValueId: attributesValuesIds[i],
      });
    });

    const attributesPart = createAttributesPart(attributes);

    const category = l2Category || l1Category;
    const categoryKey = category ? category.name : '';
    const name = `${searchQuery || categoryKey} - ${attributesName}`;

    const attributesItem = generateBreadcrumbListItem({
      name,
      position: itemListElement.length + 1,
      item: `${baseUrl}${categoriesPart}${queryPart}${attributesPart}/`,
    });
    itemListElement.push(attributesItem);
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };
};

export default getBreadcrumbs;
