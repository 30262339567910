import React, { memo } from 'react';
import isEqual from 'lodash.isequal';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { getURlWithoutJPG } from '@ecg-marktplaats/media-util-js-lib';
import ListingVipLink from '@/client/components/Listings/atoms/ListingVipLink';
import priceFormatter from '@/client/utils/formatters/priceFormatter';

const classNames = {
  contentLeft: getSuitCSSClasses({
    namespace: 'hz',
    name: 'Listing-seller-other-content',
    modifiers: ['left'],
  }),
  paragraph: getSuitCSSClasses({
    namespace: 'hz',
    name: 'Listing-seller-other-content',
    modifiers: ['paragraph'],
  }),
  contentRight: getSuitCSSClasses({ namespace: 'hz', name: 'Listing-seller-other-content', modifiers: ['right'] }),
  imageContainer: getSuitCSSClasses({ namespace: 'hz', name: 'Listing-seller-other-image-container' }),
  titleAndPrice: getSuitCSSClasses({ namespace: 'hz', name: 'Listing-seller-other-title-and-price' }),
  textLink: getSuitCSSClasses({ namespace: 'hz', name: 'Listing-seller-other-content', modifiers: ['all-items-link'] }),
  itemTitle: getSuitCSSClasses({
    namespace: 'hz',
    name: 'Listing-seller-other-content',
    modifiers: ['item-title'],
  }),
  item: getSuitCSSClasses({
    namespace: 'hz',
    name: 'Listing-seller-other-item',
  }),
  priceLabel: getSuitCSSClasses({ namespace: 'hz', name: 'text-price-label' }),
};

interface ListingOtherSellerProps {
  url: string;
  otherSellerListings: TOtherSellerListings;
  pageLocation?: string;
  correlationId?: string;
}

const ListingOtherSeller: React.FC<ListingOtherSellerProps> = ({
  otherSellerListings,
  pageLocation,
  correlationId,
  url,
}) => {
  const { t } = useI18nContext();
  const { totalSellerListingsResults: total } = otherSellerListings;
  const textKey = total > 0 ? 'subListing.seeOthers' : 'subListing.seeAll';

  return (
    <div
      className={getSuitCSSClasses({
        namespace: 'hz',
        name: 'Listing-seller-other',
      })}
    >
      <div className={classNames.contentLeft}>
        {otherSellerListings.listings && (
          <Trans tagName="span" className={classNames.paragraph} i18nKey="subListing.alsoFrom" />
        )}
        <a className={classNames.textLink} rel="nofollow" href={url}>
          <Trans tagName="span" i18nKey={textKey} totalSellerListingsResults={total} />
        </a>
      </div>
      <div className={classNames.contentRight}>
        {otherSellerListings.listings &&
          otherSellerListings.listings.map(({ vipUrl, imageUrl, title, priceInfo }) => (
            <ListingVipLink
              key={vipUrl}
              correlationId={correlationId}
              vipUrl={vipUrl}
              className={classNames.item}
              pageLocation={pageLocation}
            >
              <div className={classNames.imageContainer}>
                <img src={getURlWithoutJPG(imageUrl)} loading="lazy" decoding="async" alt={title} />
              </div>
              <div className={classNames.titleAndPrice}>
                <h3 className={classNames.itemTitle}>{title}</h3>
                <div className={classNames.priceLabel}>{t(priceFormatter(priceInfo), priceInfo)}</div>
              </div>
            </ListingVipLink>
          ))}
      </div>
    </div>
  );
};

// todo: find which properties are really needs to be checked for preventing rerendering
function arePropsEqual(prevProps: ListingOtherSellerProps, nextProps: ListingOtherSellerProps) {
  return isEqual(prevProps, nextProps);
}

export default memo(ListingOtherSeller, arePropsEqual);
