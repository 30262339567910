import React, { ReactNode } from 'react';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

interface ZrpSaveSearchHelperProps {
  children: ReactNode;
}

const ZrpSaveSearchHelper: React.FC<ZrpSaveSearchHelperProps> = ({ children }) => (
  <div className="hz-zrsp-saveSearchButton">
    <Trans className="hz-zrsp-message" i18nKey="saveSearch.messages.noResults" />
    <div className="hz-zrsp-button">{children}</div>
  </div>
);

export default ZrpSaveSearchHelper;
