export default {
  SORT_BY: 'sortBy',
  SORT_ORDER: 'sortOrder',
  SORT_ATTRIBUTE: 'sortAttribute',
  POSTCODE: 'postcode',
  DISTANCE_METERS: 'distanceMeters',

  VIEW: 'view',

  LIMIT: 'limit',

  CONDITION: 'condition',
};
