import { logger } from '@lrp/logger';
import linkGeneratorHelper from '@/utils/links/linkGeneratorHelper';
import { createCategoryKey } from '@/utils/categories';
import mergeAttributeHierarchy from '@/client/utils/mergeAttributeHierarchy';

const getCategoriesByKey = (category) => {
  if (category.parentName) {
    return {
      l1Category: { key: createCategoryKey(category.parentName) },
      l2Category: { key: createCategoryKey(category.fullName) },
    };
  }
  return {
    l1Category: { key: createCategoryKey(category.fullName) },
  };
};

const checkLanguageFilter = (attributeHierarchy = {}, locale) => {
  if (!attributeHierarchy.Language || attributeHierarchy.Language.length > 1) {
    return attributeHierarchy;
  }

  return {
    ...attributeHierarchy,
    Language: [{ attributeValueId: null, attributeValueLabel: null, attributeValueKey: locale }],
  };
};

const enrichLocaleAltWithUrl = ({ localeAlt, alternativeLocales = [], attributeHierarchy, searchRequestObject }) => {
  const alt = alternativeLocales.find((a) => a.locale === localeAlt.locale);
  const altSelectedAttributes = (alt && alt.selectedAttributes) || [];
  const languageCheckedAttributeHierarchy = checkLanguageFilter(attributeHierarchy, localeAlt.locale);

  let attributes;
  try {
    // occasionally this function fails with the error `TypeError: Cannot read property 'find' of undefined`
    // mostly during SSR, caused by bot crawl
    // it happens because attributeHierarchy is empty and languageCheckedAttributeHierarchy is then an empty object
    // this try catch is to prevent the whole page to break and help to debug the root cause
    attributes = mergeAttributeHierarchy(altSelectedAttributes, languageCheckedAttributeHierarchy);
  } catch (e) {
    e.causedBy = { languageCheckedAttributeHierarchy, altSelectedAttributes, searchRequestObject };
    logger.warn('mergeAttributeHierarchy failed', e);
  }

  const relativePath = linkGeneratorHelper({
    searchRequestObject,
    ...(alt && alt.category && { categories: getCategoriesByKey(alt.category) }),
    attributes,
  });
  const url = new URL(relativePath, localeAlt.domain);

  return {
    ...localeAlt,
    url: url.href,
  };
};

export default enrichLocaleAltWithUrl;
