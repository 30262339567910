import React from 'react';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';

import defaultBackgroundImage from './images/soi_desktop.jpg';
import defaultSellerPhoto from './images/soi_profile.jpg';

const classes = {
  backdrop: getSuitCSSClasses({ namespace: 'hz', name: 'TopSection-Backdrop' }),
  backdropStretchy: getSuitCSSClasses({
    namespace: 'hz',
    name: 'TopSection-Backdrop',
    modifiers: ['stretchy'],
  }),
  image: getSuitCSSClasses({ namespace: 'hz', name: 'TopSection-image' }),
  imagePlaceholder: getSuitCSSClasses({
    namespace: 'hz',
    name: 'TopSection-image-placeholder',
  }),
  titleWrap: getSuitCSSClasses({ namespace: 'hz', name: 'TopSection-TitleWrap' }),
  name: getSuitCSSClasses({ namespace: 'hz', name: 'TopSection-TitleWrap-Name' }),
  slogan: getSuitCSSClasses({ namespace: 'hz', name: 'TopSection-TitleWrap-Slogan' }),
};

// Originally computed like so: media.getAspectRatioPercentage(sellerProfile.backdropMedia, 980 / 360)

type TTopSectionWithImageryProps = {
  title: string;
  mission?: string;
  backdropUrl?: string;
  photo?: string;
};

const TopSectionWithImagery = ({ title, mission = '', backdropUrl = '', photo = '' }: TTopSectionWithImageryProps) => (
  <div className={`${classes.backdrop} hz-Card-block`}>
    <div
      className={classes.backdropStretchy}
      style={{ backgroundImage: `url(${backdropUrl || defaultBackgroundImage})` }}
    >
      <div className={classes.image}>
        <img src={photo || defaultSellerPhoto} alt={title} title={mission} />
      </div>
      <div className={classes.titleWrap}>
        <div className={classes.name}>{title}</div>
        {mission && <div className={classes.slogan}>{mission}</div>}
      </div>
    </div>
  </div>
);

export default TopSectionWithImagery;
