/**
 * create selected attributes part for banner data
 * @param {object} attributes
 */
const createAttributesConfig = (attributes = {}) => {
  const keys = Object.keys(attributes);
  const bannerAttributes = [];

  keys.forEach((key) => {
    const item = {
      label: key,
      value: [],
    };
    attributes[key].forEach((attribute) => {
      item.value.push(attribute.attributeValueKey.toLowerCase().replace(/ /g, '-'));
    });
    bannerAttributes.push(item);
  });

  return bannerAttributes;
};

export default createAttributesConfig;
