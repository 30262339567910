const LOCAL_STORAGE_KEY = 'lastSearches';

type TStoredSearch = {
  l1CategoryId: number;
  search: TSearchRequest;
  date: string;
};

type TAttributeGroupValue = {
  attributeValueId: number;
  attributeValueLabel: string;
};

const MAX_STORED_SEARCHES = 3;

const storeSearches = (searchRequest: TSearchRequest, facets: TFacet[]) => {
  if (!localStorage) {
    return;
  }

  try {
    const storageItem = localStorage.getItem(LOCAL_STORAGE_KEY);
    let searches = storageItem ? JSON.parse(storageItem) : [];
    const currentL1CategoryId = searchRequest.categories.l1Category.id;
    const attributeLabels = {};
    facets.forEach((facet) =>
      facet.attributeGroup?.forEach((attr: TAttributeGroupValue) => {
        if (attr && searchRequest.attributesById.includes(attr.attributeValueId)) {
          attributeLabels[attr.attributeValueId] = {
            label: attr.attributeValueLabel,
            facetId: facet.key,
          };
        }
      }),
    );

    searchRequest.attributeRanges.forEach((range) => {
      const facetForRange = facets.find(({ key }) => key === range.attributeKey);
      if (facetForRange && facetForRange.options) {
        const rangeOptions = facetForRange.options;
        let label = '';

        if (range.from) {
          const fromOption = rangeOptions['from'].find(({ value }) => value === range.from);
          label += fromOption.label;
        }
        if (range.to) {
          const toOption = rangeOptions['to'].find(({ value }) => value === range.to);
          label = label ? `${label} - ${toOption.label}` : toOption.label;
        }
        attributeLabels[facetForRange.id] = {
          facetId: facetForRange.key,
          label,
        };
      }
    });

    // Backwards compatibility
    if (!Array.isArray(searches)) {
      const migratedSearches: TStoredSearch[] = [];
      Object.keys(searches).forEach((l1CategoryId, index) => {
        if (index < MAX_STORED_SEARCHES) {
          migratedSearches.push({
            l1CategoryId: Number(l1CategoryId),
            search: searches[l1CategoryId],
            date: new Date().toJSON(),
          });
        }
      });
      searches = migratedSearches;
    }

    const matchedIndex = searches.findIndex((search) => search.l1CategoryId === currentL1CategoryId);
    if (matchedIndex >= 0) {
      searches.splice(matchedIndex, 1);
    }

    searches.unshift({
      l1CategoryId: currentL1CategoryId,
      attributeLabels,
      search: (({
        categories,
        searchQuery,
        attributes,
        attributesById,
        attributesByKey,
        attributeRanges,
        distance,
        viewOptions,
      }) => ({
        categories,
        searchQuery,
        attributes,
        attributesById,
        attributesByKey,
        attributeRanges,
        distance,
        viewOptions,
      }))(searchRequest),
      date: new Date().toJSON(),
    });

    searches.splice(MAX_STORED_SEARCHES);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(searches));
  } catch (e) {
    return;
  }
};

export default storeSearches;
