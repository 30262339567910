import React from 'react';
import { Icon, PopOverContent, PopOverTrigger, PopOverWrapper } from '@hz-design-system/web-ui';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

type THelpTooltipProps = {
  helpTextKey: string;
  isMobile: boolean;
};

const HelpTooltip = ({ helpTextKey, isMobile }: THelpTooltipProps) => (
  <PopOverWrapper as="span" openOnHover placement={isMobile ? 'top' : 'right'}>
    <PopOverTrigger>
      <Icon name="info" color="signal-action-default" />
    </PopOverTrigger>
    <PopOverContent>
      <Trans i18nKey={helpTextKey} br={Trans.safeString('<br>')} />
    </PopOverContent>
  </PopOverWrapper>
);

export default HelpTooltip;
