import React, { useState, useEffect, useContext } from 'react';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { useI18nContext, Trans } from '@ecg-marktplaats/js-react-i18n';
import { SecondaryButton, Icon, BasicModal } from '@hz-design-system/web-ui';
import { activateExperiment, createOptimizelyClient, trackExperiment } from '@ecg-marktplaats/houston-integration';

import Heading from '@/client/components/FilterSidebar/atoms/Heading';
import InfoTooltip from '@/client/components/FilterSidebar/atoms/InfoTooltip';
import linkGeneratorHelper, { generateLinkObject } from '@/utils/links/linkGeneratorHelper';
import getClientPageQueryObject from '@/client/utils/getClientPageQueryObject';
import pushToRouter from '@/client/utils/pushToRouter';

import experiments from '@/utils/labs/experiments';
import CATEGORIES_L1 from '@/constants/categories';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import { PRICE_TYPE_SALE_ATTRIBUTE_VALUE_ID } from '@/constants/attributes/nonStandards';

const LOCAL_STORAGE_KEY_DEFAULT_PRICE_TYPE = 'DefaultPriceType';
const LOCAL_STORAGE_KEY_PRICE_TYPE_SEEN = 'InfoTooltipDefaultPriceTypeSeen';

type TAttributeToggleGroupFacetProps = {
  label: string;
  attributeGroup: TAttributeValue[];
  inputKey: string;
  searchRequestObject: TSearchRequest;
  preventScroll: boolean;
};

const search = (
  inputKey: string,
  attributeValue: TAttributeValue | undefined,
  searchRequestObject: TSearchRequest,
  withAllAttributes: boolean,
) => {
  const attributes = searchRequestObject.attributes;

  delete attributes[inputKey];
  if (attributeValue) {
    attributes[inputKey] = [attributeValue];
  }

  const linkOptions = {
    searchRequestObject,
    attributes,
    withAllAttributes,
  };

  const href = linkGeneratorHelper(linkOptions);
  const pageQuery = getClientPageQueryObject(generateLinkObject(linkOptions));
  pushToRouter({ query: pageQuery, href });
};

const setDefaultPriceType = (attributeValueId: number) => {
  localStorage.setItem(LOCAL_STORAGE_KEY_DEFAULT_PRICE_TYPE, JSON.stringify(attributeValueId));
};

const AttributeToggleGroupFacet: React.FC<TAttributeToggleGroupFacetProps> = ({
  label,
  attributeGroup,
  inputKey,
  searchRequestObject,
  preventScroll,
}) => {
  const { t } = useI18nContext();
  const { labsConfig, experimentUser = null, tenantContext, houstonClientConfig } = useContext(EnvironmentContext);
  const { tenant: tenantKey } = tenantContext;
  const { isFetchResultsOnceEnabled, isFetchResultsOnceAllCatEnabled } = experiments({
    labsConfig,
  });
  const withAllAttributes =
    isFetchResultsOnceAllCatEnabled ||
    (isFetchResultsOnceEnabled && searchRequestObject.categories?.l1Category?.id === CATEGORIES_L1.DAMES_KLEREN);
  const [leaseModalOpen, setLeaseModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<TAttributeValue | undefined>(
    attributeGroup.filter(({ selected }) => selected)?.[0],
  );
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const [isDefaultPriceTypeForCarsEnabled, setIsDefaultPriceTypeForCarsEnabled] = useState(false);
  const title = t(`facets.${inputKey}`, label);

  useEffect(() => {
    (async () => {
      await createOptimizelyClient({ houstonConfig: houstonClientConfig });
      const variant = activateExperiment({
        experimentUser,
        experimentKey: 'bnl28372-price-type-improvements-v2',
        tenantKey,
      });
      setIsDefaultPriceTypeForCarsEnabled(variant === 'B');
    })();
  }, [attributeGroup, experimentUser, houstonClientConfig, tenantKey]);

  useEffect(() => {
    if (attributeGroup) {
      setSelectedValue(attributeGroup.filter(({ selected }) => selected)?.[0]);
    }
  }, [attributeGroup]);

  useEffect(() => {
    if (!isDefaultPriceTypeForCarsEnabled) {
      return;
    }

    if (selectedValue) {
      setShowInfoTooltip(false);
      setIsDefaultSet(true);
      localStorage.setItem(LOCAL_STORAGE_KEY_PRICE_TYPE_SEEN, JSON.stringify(true));
      return;
    } else if (!isDefaultSet) {
      try {
        const storageItemDefault = localStorage.getItem(LOCAL_STORAGE_KEY_DEFAULT_PRICE_TYPE);
        const storageItemTooltip = localStorage.getItem(LOCAL_STORAGE_KEY_PRICE_TYPE_SEEN);
        if (storageItemDefault) {
          setIsDefaultSet(true);
          const userDefaultPriceType = JSON.parse(storageItemDefault);
          const defaultAttribute = attributeGroup.find(
            ({ attributeValueId }) => attributeValueId === userDefaultPriceType,
          );
          search(inputKey, defaultAttribute, searchRequestObject, withAllAttributes);
        } else if (!storageItemTooltip) {
          setShowInfoTooltip(true);
        }
      } catch (e) {}
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY_DEFAULT_PRICE_TYPE);
    }
  }, [
    selectedValue,
    attributeGroup,
    inputKey,
    isDefaultPriceTypeForCarsEnabled,
    searchRequestObject,
    withAllAttributes,
    isDefaultSet,
  ]);

  const onCloseTooltip = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY_PRICE_TYPE_SEEN, JSON.stringify(true));
  };

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>, attributeValue?: TAttributeValue) => {
    localStorage.setItem(LOCAL_STORAGE_KEY_PRICE_TYPE_SEEN, JSON.stringify(true));
    setShowInfoTooltip(false);

    if (attributeValue?.attributeValueId === PRICE_TYPE_SALE_ATTRIBUTE_VALUE_ID) {
      setDefaultPriceType(attributeValue.attributeValueId);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY_DEFAULT_PRICE_TYPE);
    }

    if (preventScroll) {
      window.preventScroll = true;
    }

    trackExperiment({
      eventKey: 'price_type_change',
      experimentUser,
      tenantKey,
    });
    setSelectedValue(attributeValue);
    search(inputKey, attributeValue, searchRequestObject, withAllAttributes);
    event.currentTarget.blur();
  };

  return (
    <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter' })}>
      <Heading.H5>
        {title}
        <Icon
          className="ModalTrigger"
          name="info"
          color="signal-action-default"
          onClick={() => setLeaseModalOpen(!leaseModalOpen)}
        />
      </Heading.H5>
      <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter-toggleGroup' })}>
        <SecondaryButton
          type="button"
          className={!Boolean(selectedValue) ? 'isActive' : ''}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            handleOnClick(e);
          }}
        >
          {t('facets.all')}
        </SecondaryButton>
        {attributeGroup.map((attributeValue) => {
          const attrLabel = attributeValue.attributeValueLabel || '';
          const facetLabel = t(`facets.${attributeValue.attributeValueKey}`, attrLabel);
          const isSelected = attributeValue.selected;

          return (
            <SecondaryButton
              key={attributeValue.attributeValueId}
              type="button"
              className={isSelected ? 'isActive' : ''}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (!isSelected) {
                  handleOnClick(e, attributeValue);
                }
              }}
            >
              {facetLabel}
            </SecondaryButton>
          );
        })}
      </div>
      {showInfoTooltip && <InfoTooltip helpTextKey="facets.help.defaultPriceType" onCloseTooltip={onCloseTooltip} />}
      {leaseModalOpen && (
        <BasicModal
          size="m"
          title={t('search.pricetype.modal_title')}
          className="PriceTypeModal"
          onClose={() => setLeaseModalOpen(false)}
        >
          <Trans
            i18nKey="search.pricetype.modal"
            paragraph-open={Trans.safeString('<p>')}
            paragraph-close={Trans.safeString('</p>')}
            title-open={Trans.safeString('<h5>')}
            title-close={Trans.safeString('</h5>')}
            bold-open={Trans.safeString('<div class="hz-Text hz-Text--bodyLargeStrong">')}
            bold-close={Trans.safeString('</div>')}
            list-open={Trans.safeString('<ul>')}
            list-close={Trans.safeString('</ul>')}
            list-item-open={Trans.safeString('<li>')}
            list-item-close={Trans.safeString('</li>')}
            break={Trans.safeString('<br>')}
          />
        </BasicModal>
      )}
    </div>
  );
};

export default AttributeToggleGroupFacet;
