import { request } from '@lrp/api-client';

type TProps = {
  xsrfToken: string;
  itemIds: string[];
};

const flagListings = ({ xsrfToken, itemIds }: TProps) =>
  request({
    uri: '/lrp/api/flag-listings',
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'x-mp-xsrf': xsrfToken, 'Content-Type': 'application/json' },
    body: JSON.stringify({ itemIds }),
  });

export default flagListings;
