import sanitizeHtml from 'sanitize-html';

export default function stripHtml(text: string) {
  // a temporary solution to be removed once API response is sanitized
  // remove html tags
  const sanitizedText = sanitizeHtml(text, {
    allowedTags: [],
    allowedAttributes: {},
  });

  // avoid double spaces
  return sanitizedText.replace(/ +/g, ' ');
}
