/**
 * get experiments object by tag
 * @param {object} labsConfig
 * @param {string} tag
 * @returns {array}
 */
const getExperimentsByTag = ({ labsConfig, tag }) => {
  if (labsConfig && !labsConfig.abSwitches) {
    return [];
  }

  return Object.keys(labsConfig.abSwitches)
    .filter((key) => labsConfig.abSwitches[key].tags.indexOf(tag) > -1)
    .map((key) => Object.assign(labsConfig.abSwitches[key], { key }));
};

export default getExperimentsByTag;
