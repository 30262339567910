import React from 'react';

import AttributeGroupSingleSelectFacet from '@/client/components/FilterSidebar/molecules/AttributeGroupSingleSelectFacet';
import AttributeGroupMultiSelectFacet from '@/client/components/FilterSidebar/molecules/AttributeGroupMultiSelectFacet';
import CategoryTreeFacet from '@/client/components/FilterSidebar/molecules/CategoryTreeFacet';
import AttributeRangeFacet from '@/client/components/FilterSidebar/molecules/AttributeRangeFacet';
import Heading from '@/client/components/FilterSidebar/atoms/Heading';

interface RegularFilterProps {
  facets: TFacet[];
  title?: string;
  isMobile: boolean;
  searchRequestObject: TSearchRequest;
  totalResults: number;
  sharedDomainCookie?: string;
  searchDistanceOptions?: { name: string; value: number }[];
}

const RegularFilters: React.FC<RegularFilterProps> = ({
  facets,
  title,
  isMobile,
  searchRequestObject,
  totalResults,
}) => (
  <>
    {title && <Heading.H3 className="hz-text-header3">{title}</Heading.H3>}
    {facets.map((facet) => {
      const { key, attributeGroup, label, range, categories, singleSelect, type } = facet;

      switch (type) {
        case 'AttributeGroupFacet':
          if (singleSelect) {
            return (
              <AttributeGroupSingleSelectFacet
                key={key}
                label={label}
                attributeGroup={attributeGroup || []}
                inputKey={key}
                searchRequestObject={searchRequestObject}
              />
            );
          }
          return (
            <AttributeGroupMultiSelectFacet
              key={key}
              label={label}
              attributeGroup={attributeGroup || []}
              inputKey={key}
              searchRequestObject={searchRequestObject}
              totalResults={totalResults}
            />
          );
        case 'AttributeRangeFacet':
          return (
            <AttributeRangeFacet
              key={key}
              label={label}
              inputKey={key}
              range={range || { from: 0, to: 0 }}
              searchRequestObject={searchRequestObject}
            />
          );
        case 'CategoryTreeFacet':
          return (
            <CategoryTreeFacet
              key={key}
              categories={categories ?? []}
              inputKey={key}
              label={label}
              isMobile={isMobile}
              searchRequestObject={searchRequestObject}
            />
          );
        default:
          return null;
      }
    })}
  </>
);

export default RegularFilters;
