import formatSearchQueryFromUrl from '@/utils/formatters/formatForURL';

export const iosSmartBanner = (
  parameters: string[],
  { iosAppId, iosProtocol }: { iosAppId?: string; iosProtocol?: string } = {},
) =>
  `app-id=${iosAppId}, app-argument=${iosProtocol}://search/execute?${parameters.join(
    '&',
  )}&utm_source=smartbanner&utm_medium=smartbanner&utm_content=smartbanner&utm_campaign=smartbanner`;

export const androidDeepLink = (parameters, { androidPackage = '', androidScheme = '' } = {}): string =>
  `android-app://${androidPackage}/${androidScheme}/search/execute?${parameters.join(
    '&',
  )}&utm_source=google&utm_medium=organic`;

export const buildSmartLinkParameters = ({ categories, query, attributesValuesIds = [] }) => {
  const parameters: string[] = [];
  const { l1Category, l2Category } = categories;

  if (query) {
    parameters.push(`query=${formatSearchQueryFromUrl(query)}`);
  }
  if (l1Category || l2Category) {
    const param = l2Category ? l2Category.id : l1Category.id;
    parameters.push(`categoryId=${param}`);
  }

  if (attributesValuesIds && attributesValuesIds.length > 0) {
    parameters.push(attributesValuesIds.map((attributesValuesId) => `attributes=M,${attributesValuesId}`).join('&'));
  }

  return parameters;
};
