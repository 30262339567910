import React from 'react';
import { Icon } from '@hz-design-system/web-ui';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

const ListingVerifiedSellerBadge: React.FC = () => (
  <span className="has-tooltip">
    <div className="is-tooltip">
      <Trans className="tooltip-first-block" i18nKey="listings.verifiedSellerTooltip.line1" />
      <div className="divider" />
      <Trans i18nKey="listings.verifiedSellerTooltip.line2" />
    </div>
    <Icon name="checkmark-circle" color="signal-info-default" size="md" />
  </span>
);

export default ListingVerifiedSellerBadge;
