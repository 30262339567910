import unescape from 'unescape';
import urlPrefixes from '@/constants/urlPrefixes';
import rangeAttributeKeys from '@/constants/attributes/range';
import hashWords from '@/HOC/constants/hashWords';
import { attributeKeys, attributeValues } from '@/constants/attributes/nonStandards';
import formatPostCode from '@/utils/urlHash/formatPostCode';
import isOnlyDigits from '@/utils/strings/isOnlyDigits';
import combineRangeAttributes from './combineRangeAttributes';

const LIMIT = 30;

/**
 * Validates value for attribute: offeredSince
 * NOTE: value can also be a valid number to accommodate for epoch time values
 */
const validateOfferedSinceValue = (value) =>
  !!value && (attributeValues[attributeKeys.OFFERED_SINCE].includes(value) || isOnlyDigits(value));

/**
 * @typedef {Object} HashParams
 *
 * @property {Array<string>} attributesById
 * @property {number} limit
 * @property {Array<{attributeKey, attributeValueKey}>} attributesByKey
 * @property {Array<{attributeKey, from, to}>} attributeRanges
 * @property {{sortBy, sortOrder, sortAttribute}} sortOptions
 * @property {{postcode, distanceMeters}} distance
 * @property {{ kind }} viewOptions
 * @property {boolean} searchInTitleAndDescription
 * @property {boolean} asSavedSearch
 * @property {boolean} bypassSpellingSuggestion
 */

/**
 * @private
 * Splits and validates hash options from the provided hash string
 *
 * @param {string} hashString
 * @return {array}
 */
const splitHashOptions = (hashString) => {
  // Remove '#' and split string using the delimiter
  const hashOptions = decodeURIComponent(hashString).substring(1).split('|');

  // Split params in the hash, and remove invalid params
  return hashOptions
    .map((hashParam) => {
      const hashParamArr = hashParam.split(':');
      const key = hashParamArr[0];
      const value = hashParamArr[1];

      if (!key || !value) {
        return null;
      }

      return { key, value };
    })
    .filter((i) => i); // Filter out undefined values
};

/**
 * @private
 * Returns a HashParams object from array of split strings (from url hash)
 *
 * @param {array} splitParams
 * @param {HashParams} defaultResult - Default value returned if not processing has taken place
 * @return {HashParams}
 */
const mapper = (splitParams, defaultResult) =>
  splitParams.reduce((acc, item) => {
    const { key, value } = item;

    if (!key || !value) {
      return acc;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_originalKey, attributeKey, rangePart] = key.match(/(.+)(From|To)/, '') || [];
    if (attributeKey && Object.values(rangeAttributeKeys).includes(attributeKey)) {
      const attributeRange = {
        attributeKey,
      };
      attributeRange[rangePart.toLowerCase()] = value;
      acc.attributeRanges = acc.attributeRanges.concat(attributeRange);
    }

    if (key === urlPrefixes.query) {
      acc.query = unescape(value).split('+').map(decodeURIComponent).join(' ');
    } else if (key === urlPrefixes.attributes) {
      acc.attributesById = acc.attributesById.concat(value.split(','));
    } else if (key === attributeKeys.OFFERED_SINCE && validateOfferedSinceValue(value)) {
      acc.attributesByKey = acc.attributesByKey.concat({
        attributeKey: key,
        attributeValueKey: decodeURIComponent(value),
      });
    } else if (key === attributeKeys.LANGUAGE) {
      acc.attributesByKey = acc.attributesByKey.concat(
        value.split(',').map((lang) => ({
          attributeKey: attributeKeys.LANGUAGE,
          attributeValueKey: decodeURIComponent(lang),
        })),
      );
    } else if (key === hashWords.SORT_BY || key === hashWords.SORT_ORDER || key === hashWords.SORT_ATTRIBUTE) {
      acc.sortOptions[key] = value;
    } else if (key === hashWords.DISTANCE_METERS) {
      acc.distance[key] = value;
    } else if (key === hashWords.POSTCODE) {
      acc.distance[key] = formatPostCode(value);
    } else if (key === hashWords.VIEW) {
      acc.viewOptions.kind = value;
    } else if (key === hashWords.LIMIT) {
      acc.limit = Number(value) || LIMIT;
    } else if (key === urlPrefixes.searchInTitleAndDescription) {
      if (value === 'true') {
        acc.searchInTitleAndDescription = true;
      } else if (value === 'false') {
        acc.searchInTitleAndDescription = false;
      }
    } else if (key === urlPrefixes.asSavedSearch) {
      if (value === 'true') {
        acc.asSavedSearch = true;
      } else if (value === 'false') {
        acc.asSavedSearch = false;
      }
    } else if (key === urlPrefixes.bypassSpellingSuggestion) {
      if (value === 'true') {
        acc.bypassSpellingSuggestion = true;
      } else if (value === 'false') {
        acc.bypassSpellingSuggestion = false;
      }
    } else if (key === urlPrefixes.traits) {
      acc.traits = [value];
    } else if (key === urlPrefixes.textAttributes) {
      value.split(urlPrefixes.textAttributesSeparator).forEach((textAttributes) => {
        const splittedItem = textAttributes.split('=');
        const textAttributeKey = splittedItem[0];
        acc.attributesByKey = acc.attributesByKey.concat(
          splittedItem[1].split(',').map((textAttribute) => ({
            attributeKey: textAttributeKey,
            attributeValueKey: decodeURIComponent(textAttribute),
          })),
        );
      });
    }
    return acc;
  }, defaultResult);

/**
 * Parses URL Hash portion as HashParams object
 *
 * @param {string} hashString
 * @returns {object}
 */
const parseHash = (hashString = '') => {
  let result = {
    attributesById: [],
    attributesByKey: [],
    attributeRanges: [],
    sortOptions: {
      sortBy: null,
      sortOrder: null,
      sortAttribute: null,
    },
    distance: {
      postcode: '',
      distanceMeters: 0,
    },
    viewOptions: {},
    asSavedSearch: false,
  };

  if (hashString === '') {
    return result;
  }

  const splitParams = splitHashOptions(hashString);

  // Map strings into their corresponding hash params
  result = mapper(splitParams, result);

  // Combines range FROM and TO into PriceCents
  result.attributeRanges = combineRangeAttributes(result.attributeRanges);

  return result;
};

export default parseHash;
