import normalizeForUrl from '@/common/helpers/normalizeForUrl';

const WARRANTY_LABEL = 'warranty';
const MERCEDES_BENZ_USED = {
  CATEGORY_VALUE: 'mercedes-benz-used',
  BANNER_ATTRIBUTE_KEY: 'pl',
  BANNER_ATTRIBUTE_VALUE: 'used1',
};

/**
 * Handles a special case for Used Mercedes attributes and sanitizes the list
 * of passed through attributes
 *
 * @param selectedAttributes
 * @returns {{}}
 */
const createAttributesForBanners = (selectedAttributes) => {
  if (!Array.isArray(selectedAttributes)) {
    return {};
  }

  return selectedAttributes.reduce((accumulator, { label, value }) => {
    /**
     * If the user selected mercedes-benz-used we set the banner parameter
     * `pl` to `used1`
     */
    if (label === WARRANTY_LABEL && value.includes(MERCEDES_BENZ_USED.CATEGORY_VALUE)) {
      accumulator[MERCEDES_BENZ_USED.BANNER_ATTRIBUTE_KEY] = MERCEDES_BENZ_USED.BANNER_ATTRIBUTE_VALUE;
    }

    accumulator[normalizeForUrl(label)] = normalizeForUrl(String(value));

    return accumulator;
  }, {});
};

export default createAttributesForBanners;
