import { request } from '@lrp/api-client';

const saveSeller = (xsrfToken: string, sellerId: number) =>
  request({
    uri: `/lrp/api/soi/favoriteSellers/${sellerId}`,
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'x-mp-xsrf': xsrfToken },
  });

export default saveSeller;
