interface DataLayerTypes {
  PAGE_KEY: string;
  PAGE_KEY_GV: string;
  PAGE_TYPE_SEARCH: string;
  PAGE_TYPE_SOI: string;
  PAGE_TYPE_BROWSE: string;
  PAGE_TYPE_ZRP: string;
  CATEGORY_KEY: string;
  ATTRIBUTE_KEY: string;
  QUERY_KEY: string;
  TYPES: {
    BROWSE: string;
    SEARCH: string;
    SELLER: string;
  };
}

const dataLayer: DataLayerTypes = {
  PAGE_KEY: 'LR',
  PAGE_KEY_GV: 'GV',
  PAGE_TYPE_SEARCH: 'SEARCH',
  PAGE_TYPE_SOI: 'SOI',
  PAGE_TYPE_BROWSE: 'BROWSE',
  PAGE_TYPE_ZRP: 'ZERO',
  CATEGORY_KEY: 'c',
  ATTRIBUTE_KEY: 'ATTRIBUTES',
  QUERY_KEY: 'q',
  TYPES: {
    BROWSE: 'ResultsBrowse',
    SEARCH: 'ResultsSearch',
    SELLER: 'ResultsSeller',
  },
};

export default dataLayer;
