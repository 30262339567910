import { getCookie } from '@/client/utils/cookies';
import COOKIES from '@/constants/cookies';
import VIEW_OPTIONS from '@/constants/viewOptions';

const getViewOptionsFromCookie = () => {
  const cookieValue = getCookie(COOKIES.VIEW_TYPE);
  if (cookieValue) {
    return cookieValue === VIEW_OPTIONS.GALLERY_VIEW ? VIEW_OPTIONS.GALLERY_VIEW : VIEW_OPTIONS.LIST_VIEW;
  }

  return null;
};

export default getViewOptionsFromCookie;
