import React from 'react';

type TTopSectionWithNoImageryProps = {
  title: string;
};

const TopSectionWithNoImagery = ({ title }: TTopSectionWithNoImageryProps) => (
  <div className="seller-header__with-no-imagery-top-section">
    <div className="title">{title}</div>
  </div>
);

export default TopSectionWithNoImagery;
