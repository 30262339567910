import categories from '@/constants/categories';
import getProductLdSchema from './getProductLdSchema';
import getItemListLdSchema from './getItemListLdSchema';
const excludedCategories = [
  categories.VACATURES,
  categories.CONTACTEN_EN_BERICHTEN,
  categories.VAKANTIE,
  categories.DIENSTEN_EN_VAKMENSEN,
];

const getJsonLd = (req, res) => {
  const {
    l2Category,
    l1Category,
    searchRequestAndResponse: { attributeHierarchy, listings, originalQuery, searchRequest },
    pagination,
  } = res.locals;

  const itemListCategories = [
    categories.HUIZEN_EN_KAMERS,
    categories.HUIS_EN_INRICHTING,
    categories.DIEREN_EN_TOEBEHOREN,
  ];

  const page = pagination ? pagination.offset / pagination.limit : null;

  const searchedL1CategoryId = searchRequest?.categories?.l1Category?.id;

  // We show this only when we are on the first page (because google doesn't care about pagination)
  const shouldContinue = !excludedCategories.includes(searchedL1CategoryId) && !page && listings?.length > 0;
  if (!shouldContinue) return null;

  if (itemListCategories.includes(searchedL1CategoryId)) {
    return getItemListLdSchema(listings, searchRequest);
  }

  return getProductLdSchema(l1Category, l2Category, { attributeHierarchy, listings, originalQuery, searchRequest });
};

export default getJsonLd;
