import memoize from './memoize';

const NAME_SPACE = 'mp';

/**
 * @param input
 * @param prefix
 * @returns {string | *}
 */
const format = (prefix = '', input = []) =>
  input
    .filter((value) => Boolean(value))
    .map((value) => `${prefix}${value}`)
    .join(' ');

const getComponentName = ({ namespace, parent, name }) => {
  if (parent) {
    return `${namespace}-${parent}-${name}`;
  }
  return `${namespace}-${name}`;
};

/**
 * @param {Object} input - Object describing the component to be styled
 * @param {string} input.namespace
 * @param {string} input.name
 * @param {string} input.parent
 * @param {string[]} input.states
 * @param {string[]} input.modifiers
 * @param {string[]} input.utilities
 * @param {string} input.icon
 * @param {string} input.custom
 * @returns {string}
 */
const getSuitCSSClasses = ({
  namespace = NAME_SPACE,
  name = '',
  parent = '',
  states = [],
  modifiers = [],
  utilities = [],
  icon = '',
  custom = '',
} = {}) => {
  if (!name) {
    return '';
  }

  const componentClassName = getComponentName({
    namespace,
    parent,
    name,
  });

  const classNames = [
    componentClassName,
    format(`${componentClassName}--`, modifiers),
    format('is-', states),
    format('u-', utilities),
    format(`${namespace}-svg-`, [icon]),
    custom || '',
  ];

  return classNames.filter((className) => Boolean(className)).join(' ');
};

export default memoize(getSuitCSSClasses);
