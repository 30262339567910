/* eslint-disable no-shadow */

enum PRICE_TYPE {
  MIN_BID = 'MIN_BID',
  FIXED = 'FIXED',
  NOTK = 'NOTK',
  FAST_BID = 'FAST_BID',
  NVT = 'NVT',
  EXCHANGE = 'EXCHANGE',
  FREE = 'FREE',
  RESERVED = 'RESERVED',
  ON_REQUEST = 'ON_REQUEST',
  SEE_DESCRIPTION = 'SEE_DESCRIPTION',
}

export default PRICE_TYPE;
