import React, { ReactNode } from 'react';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';

interface ListingAttributeProps {
  children: ReactNode;
  type?: string;
}

const ListingAttribute: React.FC<ListingAttributeProps> = ({ children, type }) => (
  <span
    className={getSuitCSSClasses({
      namespace: 'hz',
      name: 'Attribute',
      modifiers: [type],
    })}
    suppressHydrationWarning
  >
    {children}
  </span>
);

export default ListingAttribute;
