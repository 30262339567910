import React, { useContext, useState, useEffect } from 'react';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import Cookies from 'js-cookie';
import { IconButton, Link } from '@hz-design-system/web-ui';
import trackEvent from '@/client/utils/analytics/trackEvent';
import trackingKeys from '@/client/constants/analytics/trackingKeys';
import isSsr from '@/client/utils/isSsr';

type TNDFCGenericRankingsTextProps = {
  sharedDomainCookie?: string;
};

const NDFCGenericRankingsText: React.FC<TNDFCGenericRankingsTextProps> = ({ sharedDomainCookie }) => {
  const { NDFCTextDismissed } = useContext(EnvironmentContext);
  const [show, setShow] = useState(!NDFCTextDismissed);

  const { t } = useI18nContext();

  const handleClose = () => {
    if (isSsr()) return;
    const THREE_MONTHS = 3;
    const now = new Date();
    now.setMonth(now.getMonth() + THREE_MONTHS);
    Cookies.set('NDFCTextDismissed', '1', { expires: now, path: '/', domain: sharedDomainCookie });
    trackEvent({
      eventAction: trackingKeys.NDFC_RANKING_TEXT.EVENT_ACTION.CLOSED,
      eventLabel: trackingKeys.NDFC_RANKING_TEXT.EVENT_LABEL,
    });
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      trackEvent({
        eventAction: trackingKeys.NDFC_RANKING_TEXT.EVENT_ACTION.DISPLAYED,
        eventLabel: trackingKeys.NDFC_RANKING_TEXT.EVENT_LABEL,
      });
    }
  }, [show]);

  if (!show) return null;

  return (
    <div className="ndfc-wrapper" data-testid="ndfc-generic-text">
      <p>
        <Trans i18nKey="ndfc.genericRankings.text" tagName="span" />{' '}
        <Link kind="paragraph" href={t('ndfc.genericRankings.link.url')} target="_blank">
          <Trans i18nKey="ndfc.genericRankings.link.text" tagName="span" />
        </Link>
      </p>
      <IconButton.Tertiary name="close" ariaLabel="Close" size="small" onClick={handleClose} data-testid="ndfc-close" />
    </div>
  );
};

export default NDFCGenericRankingsText;
