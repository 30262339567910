import parseHash from '@/HOC/utils/urlHash/parseHash';
import removeDuplicates from '@/HOC/utils/arrays/removeDuplicates';
import getViewOptionsFromCookie from '@/HOC/utils/viewOptionCookie';
import { getCookie } from '@/client/utils/cookies';
import formatPostCode from '@/utils/urlHash/formatPostCode';
import { defaults } from '@/constants/attributes/nonStandards';

import COOKIES from '@/constants/cookies';

const getPostcodeFromCookie = () => {
  const cookieValue = getCookie(COOKIES.SEARCH_POST_CODE);

  return cookieValue ? formatPostCode(cookieValue) : '';
};

const determineViewKind = (viewOptionsFromHash) => {
  if (viewOptionsFromHash && viewOptionsFromHash.kind) {
    return viewOptionsFromHash.kind;
  }

  const viewKindFromCookie = getViewOptionsFromCookie();
  if (viewKindFromCookie) {
    return viewKindFromCookie;
  }

  return defaults.view;
};

export default function assembleSearchRequest(query) {
  const {
    l1CategoryId,
    l2CategoryId,
    sellerId,
    searchQuery,
    offset,
    limit,
    attributesValuesIds,
    textAttributesValuesLabels,
    textAttributesValuesKeys,
  } = query;

  const hashParams = parseHash(window.location.hash);

  if (textAttributesValuesKeys) {
    if (Array.isArray(textAttributesValuesKeys)) {
      textAttributesValuesKeys.forEach((value, i) => {
        hashParams.attributesByKey.push({
          attributeKey: value,
          attributeValueKey: textAttributesValuesLabels[i],
        });
      });
    } else {
      hashParams.attributesByKey.push({
        attributeKey: textAttributesValuesKeys,
        attributeValueKey: textAttributesValuesLabels,
      });
    }
  }

  return {
    categories: {
      ...(l1CategoryId && { l1Category: { id: l1CategoryId } }),
      ...(l2CategoryId && { l2Category: { id: l2CategoryId } }),
    },
    ...(sellerId && { seller: sellerId }),
    searchQuery: hashParams.query || searchQuery, // get the query from router first
    attributesById: removeDuplicates(hashParams.attributesById.concat(attributesValuesIds || [])),
    attributesByKey: removeDuplicates(
      hashParams.attributesByKey,
      (x, y) => x.attributeKey === y.attributeKey && x.attributeValueKey === y.attributeValueKey,
    ),
    attributeRanges: hashParams.attributeRanges,
    sortOptions: hashParams.sortOptions,
    pagination: {
      offset,
      limit: hashParams.limit ? hashParams.limit : limit,
    },
    distance: {
      postcode: hashParams.distance.postcode || getPostcodeFromCookie(),
      distanceMeters: hashParams.distance.distanceMeters,
    },
    viewOptions: {
      kind: determineViewKind(hashParams.viewOptions),
    },
    searchInTitleAndDescription: true,
    asSavedSearch: hashParams.asSavedSearch,
    bypassSpellingSuggestion: hashParams.bypassSpellingSuggestion,
    traits: hashParams.traits,
  };
}
