import React, { useState, useEffect, useContext, ReactNode } from 'react';
import { MaskedLink, Link } from '@hz-design-system/web-ui';
import { enrichVipUrlWithTrackingData } from '@/utils/links/linkHelpers';
import { setCookie } from '@/client/utils/cookies';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';

const createCorrelationIdCookieAndStoreScrollPos = (correlationId: string) => {
  if (typeof window === 'undefined') return;

  if (correlationId) {
    setCookie('searchRequestId', correlationId);
  }

  sessionStorage.setItem(
    'lrp_scroll_store',
    JSON.stringify({
      href: window.location.href,
      pos: window.scrollY,
      height: document.querySelector('.hz-Page-body')?.scrollHeight,
    }),
  );
};

interface ListingVipLinkProps {
  vipUrl?: string;
  trackingData?: string;
  correlationId?: string;
  className?: string;
  pageLocation?: string;
  children: ReactNode;
  thinContent?: boolean;
}

const ListingVipLink: React.FC<ListingVipLinkProps> = ({
  vipUrl = '',
  trackingData,
  correlationId,
  className,
  pageLocation,
  children,
  thinContent,
}) => {
  const { isBot } = useContext(EnvironmentContext);
  const [href, setHref] = useState(vipUrl);

  useEffect(() => {
    if (vipUrl && !isBot) {
      setHref(
        enrichVipUrlWithTrackingData({
          vipUrl,
          trackingData,
          pageLocation,
        }),
      );
    }
  }, [vipUrl, isBot, trackingData, pageLocation]);

  const handleClick = () => {
    createCorrelationIdCookieAndStoreScrollPos(correlationId!);
  };

  const handleContextMenu = () => {
    if (vipUrl) {
      setHref(
        enrichVipUrlWithTrackingData({
          vipUrl,
          trackingData,
          pageLocation,
          correlationId,
        }),
      );
    }
  };

  const TagName = thinContent ? MaskedLink : Link;

  return (
    <TagName
      suppressHydrationWarning
      href={href}
      data-tracking={trackingData}
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      className={className}
      kind="block"
    >
      {children}
    </TagName>
  );
};

export default ListingVipLink;
