import { logger } from '@lrp/logger';
import isSsr from '@/client/utils/isSsr';

const trackEvent = ({ eventAction = '', eventLabel = '', eventMetric = '' }) => {
  try {
    // do not log warnings if it is SSR
    if (isSsr()) return;
    if (eventAction || eventLabel) {
      const event = { eventAction, eventLabel };
      if (eventMetric) {
        Object.entries(eventMetric).map(([key, value]) => {
          event[key] = value;
        });
      }

      if (window.ecGa && window.ga) {
        window.ecGa('trackEvent', event);
      } else {
        window.ecGaEventsOnLoad = window.ecGaEventsOnLoad || [];
        window.ecGaEventsOnLoad.push(event);
      }
    }
  } catch (err) {
    logger.warn('GA trackEvent', err);
  }
};

export default trackEvent;
