import { currency } from '@ecg-marktplaats/aurora-js-formatters';
import PRICE_TYPE from '../../constants/priceTypes';
import { PriceInfo } from '@/client/types/Listings';

const priceFormatter = ({ priceCents, priceType, suppressZeroCents = false }: PriceInfo): string => {
  const minBid = priceType === PRICE_TYPE.MIN_BID;
  const fixed = priceType === PRICE_TYPE.FIXED;

  if (!priceType || minBid || fixed) {
    return currency(priceCents, true, suppressZeroCents);
  }

  return `prices.${priceType}`;
};

export default priceFormatter;
