import React from 'react';
import { TertiaryButton, ButtonIcon } from '@hz-design-system/web-ui';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import VIEW_OPTIONS from '@/constants/viewOptions';
import ViewControlGroup from '@/client/components/ViewControls/atoms/ViewControlGroup';

type TViewOptionsControl = {
  kind: string;
  onChange: (kind: string) => void;
};

const ViewOptionsControl = ({ kind, onChange }: TViewOptionsControl) => {
  const { t } = useI18nContext();

  return (
    <ViewControlGroup type="view-options">
      <TertiaryButton
        className={kind === VIEW_OPTIONS.LIST_VIEW ? 'isActive' : ''}
        onClick={() => onChange(VIEW_OPTIONS.LIST_VIEW)}
        size="small"
        aria-label={t('viewControls.list')}
      >
        <ButtonIcon name="results-list" isStartIcon />
        <Trans className="view-option-label" i18nKey="viewControls.list" tagName="span" />
      </TertiaryButton>
      <TertiaryButton
        className={kind === VIEW_OPTIONS.GALLERY_VIEW ? 'isActive' : ''}
        onClick={() => onChange(VIEW_OPTIONS.GALLERY_VIEW)}
        size="small"
        aria-label={t('viewControls.photos')}
      >
        <ButtonIcon name="results-tiles" isStartIcon />
        <Trans className="view-option-label" i18nKey="viewControls.photos" tagName="span" />
      </TertiaryButton>
    </ViewControlGroup>
  );
};

export default ViewOptionsControl;
