const rangeAttributeKeys = {
  PRICE_CENTS: 'PriceCents',
  MILEAGE: 'mileage',
  CONSTRUCTION_YEAR: 'constructionYear',
  NUMBER_OF_SEATS: 'numberOfSeats',
  NUMBER_OF_SEATS_BE: 'numberOfSeatsBE',
  ENGINE_HORSE_POWER: 'engineHorsepower',
  ENGINE_POWER_KW: 'enginePowerKW',
  ENGINE_DISPLACEMENT: 'engineDisplacement',
  NUMBER_OF_CYLINDERS: 'numberOfCilindersCars',
  BATTERY_CAPACITY: 'batteryCapacity',
  BATTERY_CHARGE_TIME: 'batteryChargeTime',
  BATTERY_FAST_CHARGE_TIME: 'batteryFastChargeTime',
  RANGE: 'range',
  TOWING_WEIGHT_BRAKES: 'towingWeightBrakes',
  TOWING_WEIGHT_NO_BRAKES: 'towingWeightNoBrakes',
  ROAD_TAX: 'roadTax',
  EMPTY_WEIGHT: 'emptyWeightCars',
};

export const minRanges = [
  rangeAttributeKeys.RANGE,
  rangeAttributeKeys.TOWING_WEIGHT_BRAKES,
  rangeAttributeKeys.TOWING_WEIGHT_NO_BRAKES,
];

export const maxRanges = [
  rangeAttributeKeys.BATTERY_CHARGE_TIME,
  rangeAttributeKeys.BATTERY_FAST_CHARGE_TIME,
  rangeAttributeKeys.ROAD_TAX,
  rangeAttributeKeys.EMPTY_WEIGHT,
];

export default rangeAttributeKeys;
