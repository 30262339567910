import dataLayerKeys from '@/server/constants/dataLayer';

/**
 * vURL generator for analytics
 * @param {object} categories
 * @param {string} query
 * @param {bool} isSOI
 * @param {bool} isZRP
 * @param {bool} hasAttributes
 */
const vurlGenerator = ({ categories, query, isSOI, isZRP, hasAttributes }) => {
  const {
    PAGE_KEY,
    PAGE_TYPE_SOI,
    QUERY_KEY,
    PAGE_TYPE_SEARCH,
    PAGE_TYPE_ZRP,
    CATEGORY_KEY,
    PAGE_TYPE_BROWSE,
    ATTRIBUTE_KEY,
  } = dataLayerKeys;

  if (isSOI) {
    return `/${PAGE_KEY}/${PAGE_TYPE_SOI}`;
  }

  const attributeKey = hasAttributes ? `/${ATTRIBUTE_KEY}` : '';
  const pageType = query ? PAGE_TYPE_SEARCH : PAGE_TYPE_BROWSE;
  const queryPart = query ? `&${QUERY_KEY}=${query}` : '';
  let categoriesPart = '';

  if (categories) {
    const l1CategoryPart = categories.l1Category ? `/${categories.l1Category.id}_${categories.l1Category.key}` : '';
    const l2CategoryPart = categories.l2Category ? `/${categories.l2Category.id}_${categories.l2Category.key}` : '';
    categoriesPart = l1CategoryPart + l2CategoryPart;
  }

  let currentCategoryKey = `${CATEGORY_KEY}=0`;
  currentCategoryKey =
    categories && categories.l1Category
      ? `${CATEGORY_KEY}=${categories.l1Category.id}_${categories.l1Category.key}`
      : currentCategoryKey;
  currentCategoryKey =
    categories && categories.l2Category
      ? `${CATEGORY_KEY}=${categories.l2Category.id}_${categories.l2Category.key}`
      : currentCategoryKey;
  currentCategoryKey = query ? currentCategoryKey : '';

  const queryStringSeperator = currentCategoryKey || queryPart ? '?' : '';
  if (isZRP) {
    return `/${PAGE_KEY}${categoriesPart}/${pageType}/${PAGE_TYPE_ZRP}${queryStringSeperator}${currentCategoryKey}${queryPart}`;
  }

  return `/${PAGE_KEY}${categoriesPart}/${pageType}${attributeKey}${queryStringSeperator}${currentCategoryKey}${queryPart}`;
};

export default vurlGenerator;
