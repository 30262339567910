import { getSeoFriendlyCategoryName } from '@ecg-marktplaats/aurora-node-data-layer';
/**
 * Converts the supplied categories object to dataLayer format
 * Assigns 'null' instead of -1 to undefined categories' ids
 *
 * @param {Object} categories
 * @params {{id: number, key: string}} categories.l1Category
 * @params {{id: number, key: string}} [categories.l2Category]
 * @return {{c: {id, n}, l1: {id, n}, l2: {id, n}}}
 */
const categoriesToDataLayerCategories = (categories) => {
  const { l1Category, l2Category } = categories;

  let cField = { id: 0 };
  let l1Field = { id: 0 };
  let l2Field = { id: 0 };

  if (l2Category) {
    cField = { id: l2Category.id, n: l2Category.key };
    l1Field = { id: l1Category.id, n: l1Category.key };
    l2Field = { id: l2Category.id, n: l2Category.key };
  } else if (l1Category) {
    cField = { id: l1Category.id, n: l1Category.key };
    l1Field = { id: l1Category.id, n: l1Category.key };
  }

  return { c: cField, l1: l1Field, l2: l2Field, sfc: getSeoFriendlyCategoryName(l1Field) };
};

export default categoriesToDataLayerCategories;
