import { ImageSize, formatImageUrl, getURlWithoutJPG } from '@ecg-marktplaats/media-util-js-lib';
import { IMAGE_ALT_TEXT_LENGTH_LIMIT } from '@/client/constants/listings';

const generateImageAlt = (title, categories, extendedAttributes) => {
  const l1Category = categories?.l1Category?.fullName;
  const l2Category = categories?.l2Category?.fullName;
  let formattedAltText = `${title}`;

  if (l1Category) {
    formattedAltText += `, ${l1Category}`;
  }
  if (l2Category) {
    formattedAltText += `, ${l2Category}`;
  }

  extendedAttributes?.forEach((attr) => {
    if (attr.value && formattedAltText.length < IMAGE_ALT_TEXT_LENGTH_LIMIT) {
      formattedAltText += `, ${attr.value}`;
    }
  });

  return formattedAltText;
};

const generateImageObject = (image, title, categories, extendedAttributes) => ({
  alt: generateImageAlt(title, categories, extendedAttributes),
  title: generateImageAlt(title, categories, extendedAttributes),
  imageSource: formatImageUrl(image.url, ImageSize.Medium, image.sizes, categories, extendedAttributes),
  url: getURlWithoutJPG(image.url),
  sizes: image.sizes,
});

const imageFormatter = (images, title, categories = {}, extendedAttributes = []) =>
  images ? images.map((image) => generateImageObject(image, title, categories, extendedAttributes)) : [];

export default imageFormatter;
