import { BANNER_POSITIONS } from '@/constants/banners';
import VIEW_OPTIONS from '@/constants/viewOptions';

/**
 * Function for determining which banner positions to load
 * for the current view.
 *
 * @param view - lrp view the user is in
 * @param seller - seller info
 */
const getBannerPositions = (view: string, seller: TSeller): Array<string> => {
  if (seller) {
    return BANNER_POSITIONS.SOI;
  }

  if (view !== VIEW_OPTIONS.LIST_VIEW) {
    return BANNER_POSITIONS.LRP_GRID_VIEW;
  }

  const positions = [...BANNER_POSITIONS.LRP_LIST_VIEW];

  return positions;
};

export default getBannerPositions;
