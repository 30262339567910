class ClientError extends Error {
  code: unknown;
  details: unknown;
  constructor(responseBody: { code: unknown; message: string; details: unknown } | string) {
    super('CLIENT_ERROR');
    if (typeof responseBody === 'string') {
      this.message = responseBody;
    } else {
      const { code, message, details } = responseBody;
      this.code = code;
      this.message = message;
      this.details = details;
    }
    this.name = 'CLIENT_ERROR';
  }
}

export default ClientError;
