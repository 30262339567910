import serialize from 'serialize-javascript';

/**
 * Returns safe serialized json that can be used inside script tag
 *
 * Serialized data should not contain any function or regexps values
 *
 * @param {Object} data
 * @return {String} serialized object
 */
const safeJsonSerialize = (data) => serialize(data, { isJSON: true });

export default safeJsonSerialize;
