import 'isomorphic-fetch';

const deleteSeller = (xsrfToken: string, sellerId: number): Promise<Response> =>
  fetch(`/lrp/api/soi/favoriteSellers/${sellerId}`, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: { 'x-mp-xsrf': xsrfToken },
  });

export default deleteSeller;
