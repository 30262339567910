const hasAttributes = ({ attributesById = [], attributesByKey = [], attributeRanges = [] }) =>
  attributesById.length || attributesByKey.length || attributeRanges.length;

const hasQuery = (searchRequest) => searchRequest.searchQuery !== '';

const hasNonValuableAttributes = (searchRequest) => {
  const nonValuableAttributeKeys = Object.keys(searchRequest.attributes).filter(
    (key) =>
      searchRequest.seoFriendlyAttributes.indexOf(key) < 0 &&
      !searchRequest.attributes[key].every((value) => value.isDefault),
  );
  return nonValuableAttributeKeys.length > 0;
};

const getRobotsContent = (searchRequest, hasResults, isFetchResultsOnceAllCatEnabled) => {
  if (isFetchResultsOnceAllCatEnabled) {
    return (hasAttributes(searchRequest) || hasQuery(searchRequest)) &&
      (!hasResults || hasNonValuableAttributes(searchRequest))
      ? 'noindex, follow'
      : 'index, follow';
  } else {
    return (hasAttributes(searchRequest) || hasQuery(searchRequest)) && !hasResults
      ? 'noindex, follow'
      : 'index, follow';
  }
};

export default getRobotsContent;
