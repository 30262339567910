import React, { ReactNode } from 'react';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';

type ParagraphProps = {
  children: ReactNode;
  kind: string;
};

const Paragraph: React.FC<ParagraphProps> = ({ children, kind }) => (
  <p className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter-heading', modifiers: [kind] })}>{children}</p>
);

const H3Component: React.FC<{ children: ReactNode; className?: string }> = ({ children }) => (
  <Paragraph kind="3">{children}</Paragraph>
);

const H5Component: React.FC<{ children: ReactNode }> = ({ children }) => <Paragraph kind="5">{children}</Paragraph>;

class Heading extends React.Component {
  static H3 = H3Component;

  static H5 = H5Component;
}

export default Heading;
