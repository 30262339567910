import COOKIES from '@/constants/cookies';
import isSsr from '@/client/utils/isSsr';

const crmIdParameterPerTenant = {
  mp: 'crmid',
  twh: 'crmid_twh',
};
const customerOrganisationId = 'ACC17B1E598C733C0A495DA0@AdobeOrg';
const initConfig = {
  partner: 'marktplaats',
  containerNSID: 0,
  uuidCookie: {
    name: COOKIES.ADOBE_IDENTIFIER,
    days: 30,
  },
  visitorService: {
    namespace: customerOrganisationId,
  },
};

const adobeRegistration = ({ audienceTargeting, user, tenantContext }) => {
  if (isSsr()) return;
  const consent = window?._sp_lib?.getConsentState(document.cookie)?.AdobeAudienceManager;
  if (audienceTargeting.isEnabled && consent) {
    import('@ecg-marktplaats/adobe-dmp-js/src/adobeVisitorApi').then((AdobeVisitorApi) => {
      import('@ecg-marktplaats/adobe-dmp-js/src/adobeDIL').then((AdobeDIL) => {
        AdobeDIL.default.create(initConfig);

        const encryptedUserId = user.isLoggedIn && user.encryptedId;
        const crmId = crmIdParameterPerTenant[tenantContext.tenant];

        if (encryptedUserId && crmId) {
          const visitorApiInstance = AdobeVisitorApi.default.getInstance(customerOrganisationId);

          visitorApiInstance.setCustomerIDs({
            [crmId]: {
              id: encryptedUserId,
              authState: AdobeVisitorApi.default.AuthState ? AdobeVisitorApi.default.AuthState.AUTHENTICATED : null,
            },
          });
        }
      });
    });
  }
};

export default adobeRegistration;
