import 'isomorphic-fetch';
import ApiError from './ApiError';
import ClientError from './ClientError';
import isSuccessResponse from './isSuccessResponse';
import { logger } from '@lrp/logger';

type TParams = {
  method?: string;
  uri: string;
  headers?: HeadersInit;
  credentials: RequestCredentials;
  body?: BodyInit;
};

const request = async <T>({ method, uri, headers, credentials, body }: TParams): Promise<T> => {
  let response: Response;

  try {
    response = await fetch(uri, {
      method,
      credentials,
      headers,
      body,
    });
  } catch (err) {
    logger.warn('Error calling LRP API url', err);
    throw new ApiError(); // if servers fails to respond
  }

  const responseText = await response.text();

  if (!isSuccessResponse(response)) {
    logger.warn(
      'Error calling LRP API url',
      `uri: ${uri}, response status: ${response.status}, response text: ${responseText}`,
    );
    throw new ClientError(responseText);
  }

  return responseText.length ? JSON.parse(responseText) : {};
};

export default request;
