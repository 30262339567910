type AttributeRange = {
  attributeKey: string;
  from: any;
  to: any;
};

type CombinedAttributeRange = {
  attributeKey: string;
  from: any;
  to: any;
};

const combineRangeAttributes = (attributeRanges: AttributeRange[]): CombinedAttributeRange[] => {
  const rangeAttrMap = attributeRanges.reduce(
    (acc: { [key: string]: { from: any; to: any } }, attributeRange: AttributeRange) => {
      const { attributeKey, from, to } = attributeRange;

      if (!acc[attributeKey]) {
        acc[attributeKey] = {
          from: null,
          to: null,
        };
      }

      acc[attributeKey] = {
        from: acc[attributeKey].from ? acc[attributeKey].from : from,
        to: acc[attributeKey].to ? acc[attributeKey].to : to,
      };

      return acc;
    },
    {},
  );

  return Object.keys(rangeAttrMap).map((k) => {
    const { from, to } = rangeAttrMap[k];
    return { attributeKey: k, from, to };
  });
};

export default combineRangeAttributes;
