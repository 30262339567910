import React, { useState, useContext, useEffect } from 'react';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { TextField } from '@hz-design-system/web-ui';
import experiments from '@/utils/labs/experiments';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import Heading from '@/client/components/FilterSidebar/atoms/Heading';
import linkGeneratorHelper, { generateLinkObject } from '@/utils/links/linkGeneratorHelper';
import getClientPageQueryObject from '@/client/utils/getClientPageQueryObject';
import pushToRouter from '@/client/utils/pushToRouter';

type TTextFacetProps = {
  inputKey: string;
  searchRequestObject: TSearchRequest;
};

const QueryFilter = ({ inputKey, searchRequestObject }: TTextFacetProps) => {
  const { t } = useI18nContext();
  const { labsConfig } = useContext(EnvironmentContext);
  const { isFetchResultsOnceAllCatEnabled } = experiments({ labsConfig });
  const [value, setValue] = useState(searchRequestObject.searchQuery);

  useEffect(() => {
    setValue(searchRequestObject.searchQuery);
  }, [searchRequestObject.searchQuery]);

  const searchWithVariant = () => {
    window.preventScroll = true;
    const options = {
      searchRequestObject,
      withAllAttributes: isFetchResultsOnceAllCatEnabled,
      query: value,
    };

    const href = linkGeneratorHelper(options);
    // @ts-ignore
    const pageQuery = getClientPageQueryObject(generateLinkObject(options));
    pushToRouter({ query: pageQuery, href });
  };

  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchWithVariant();
    }
  };

  return (
    <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter' })}>
      <Heading.H5>{t(`facets.${inputKey}`)}</Heading.H5>
      <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter-text' })}>
        <TextField
          type="text"
          placeholder={t(`facets.${inputKey}Placeholder`)}
          onChange={(e) => setValue(e.target.value)}
          onBlur={searchWithVariant}
          onKeyPress={handleOnKeyPress}
          value={value}
        />
      </div>
    </div>
  );
};

export default QueryFilter;
