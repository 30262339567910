import { request } from '@lrp/api-client';
import searchRequestToQueryString from '@/client/actions/searchRequestToQueryString';

const fetchSearchResults = (searchRequest: TSearchRequest): Promise<TSearchRequestAndResponse> =>
  request({
    uri: `/lrp/api/search?${searchRequestToQueryString(searchRequest)}`,
    method: 'GET',
    credentials: 'same-origin',
  });

export default fetchSearchResults;
