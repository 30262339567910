import formatAttributeRange from '@/client/utils/formatters/formatBasedOnKey';
import distanceKmFormatter from '@/client/utils/formatters/distanceKmFormatter';
import { attributeKeys } from '@/constants/attributes/nonStandards';
import TRAITS from '@/constants/traits';
import CATEGORIES_L1 from '@/constants/categories';

const attributeKeysToShowLabelFor = ['numberOfDoors', 'numberOfSeats', 'numberOfSeatsBE'];

const filterChipsHelper = (searchRequestObject, categoriesById, facets, t) => {
  const findAttributeLabel = (attrKey) =>
    facets.filter((f) => f.key === attrKey).reduce((_m, v) => t(`facets.${v.key}`, v.label), '');

  const rangeLabel = (key, from, to, facet) => {
    const formattedRange = {
      from:
        facet.options?.from && from && facet.options.from.find(({ value }) => value === from)
          ? facet.options.from.find(({ value }) => value === from).label
          : formatAttributeRange(key, from, false, t),
      to:
        facet.options?.to && to && facet.options.to.find(({ value }) => value === to)
          ? facet.options.to.find(({ value }) => value === to).label
          : formatAttributeRange(key, to, false, t),
    };

    let label;
    if (typeof from !== 'undefined' && typeof to !== 'undefined') {
      label = `${formattedRange.from} - ${formattedRange.to}`;
    } else if (typeof from !== 'undefined') {
      label = t('viewControls.chips.rangeFrom', formattedRange);
    } else {
      label = t('viewControls.chips.rangeTo', formattedRange);
    }

    return label;
  };

  const parentCategoryIsCars = (parentCategory) => {
    return parentCategory.id === CATEGORIES_L1.CARS;
  };

  const removeCarModelAttribute = (attributes) => {
    const { ...remainingAttributes } = attributes;
    delete remainingAttributes[attributeKeys.CAR_MODEL];
    return remainingAttributes;
  };

  const chips = [];
  const {
    categories: { l1Category, l2Category },
    distance,
    traits,
    attributes,
    attributeRanges,
  } = searchRequestObject;

  if (l1Category) {
    const label = categoriesById[l1Category.id].name;
    chips.push({
      type: 'l1category',
      label,
      key: l1Category.key,
      linkProps: {
        categories: {},
        removeAllFilters: !searchRequestObject.searchQuery && !searchRequestObject.seller,
        removeAllAttributes: true,
      },
    });
  }

  if (l2Category) {
    const label = categoriesById[l2Category.id].name;

    if (parentCategoryIsCars(l1Category)) {
      chips.push({
        type: 'l2category',
        label,
        key: l2Category.key,
        linkProps: {
          categories: {
            l1Category: {
              key: l1Category.key,
              id: l1Category.id,
            },
          },
          // Do not remove attributes when closing the L2 chip within the Cars category, as attributes are shared.
          removeAllAttributes: false,
          searchRequestObject: {
            ...searchRequestObject,
            // Explicitly remove the car model attribute for car categories, as it is specific to the L2.
            attributes: removeCarModelAttribute(attributes),
          },
        },
      });
    } else {
      chips.push({
        type: 'l2category',
        label,
        key: l2Category.key,
        linkProps: {
          categories: {
            l1Category: {
              key: l1Category.key,
              id: l1Category.id,
            },
          },
          removeAllAttributes: true,
        },
      });
    }
  }

  if (distance && distance.distanceMeters && distance.postcode) {
    const kms = distanceKmFormatter(distance.distanceMeters);
    const label = t('filters.distance', { kms });

    chips.push({
      type: 'attributes',
      key: 'attrchip-distance',
      label,
      linkProps: {
        distance: {},
      },
    });
  }

  if (traits && traits.includes(TRAITS.PROFILE)) {
    const label = t('filters.entrepreneurs');
    chips.push({
      label,
      linkProps: {
        searchRequestObject: {
          ...searchRequestObject,
          traits: [],
        },
      },
    });
  }

  Object.keys(attributes).forEach((key) => {
    const attributeList = attributes[key];
    attributeList.forEach((attribute) => {
      if (attribute.attributeValueId) {
        if (attributeKeysToShowLabelFor.includes(key)) {
          chips.push({
            type: 'attributes',
            key: `attrchip-${attribute.attributeValueId}`,
            label: `${findAttributeLabel(key)}: ${attribute.attributeValueLabel}`,
            linkProps: {
              attributes: { [key]: attribute },
              isSelected: true,
            },
          });
        } else {
          chips.push({
            type: 'attributes',
            key: `attrchip-${attribute.attributeValueId}`,
            label: attribute.attributeValueLabel,
            linkProps: {
              attributes: { [key]: attribute },
              isSelected: true,
            },
          });
        }
      } else if (key === attributeKeys.OFFERED_SINCE && attribute.isDefault === false) {
        chips.push({
          type: 'attributes',
          key: `attrchip-${key}`,
          label: t(`facets.${attribute.attributeValueKey}`),
          linkProps: {
            attributes: { [key]: attribute },
            isSelected: true,
          },
        });
      } else if (
        key !== attributeKeys.OFFERED_SINCE &&
        attribute.attributeValueId === null &&
        key !== attributeKeys.LANGUAGE
      ) {
        // Text attributes
        chips.push({
          type: 'attributes',
          key: `attrchip-${key}`,
          label: attribute.attributeValueLabel,
          linkProps: {
            attributes: { [key]: attribute },
            isSelected: true,
          },
        });
      }
    });
  });

  attributeRanges.forEach((range) => {
    const key = range.attributeKey;
    const rangeFacet = facets.find((facet) => facet.key === key);
    chips.push({
      key: `rangechip-${key}`,
      label: rangeLabel(key, range.from, range.to, rangeFacet),
      linkProps: {
        rangeAttributes: attributeRanges.filter((r) => r.attributeKey !== key),
        isSelected: true,
      },
    });
  });

  return chips;
};

export default filterChipsHelper;
