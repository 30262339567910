import React, { useContext } from 'react';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import TopSectionWithImagery from '@/client/components/SellerHeader/TopSection/WithImagery';
import TopSectionWithNoImagery from '@/client/components/SellerHeader/TopSection/WithNoImagery';
import Tabs from '@/client/components/SellerHeader/Tabs';
import Info from '@/client/components/SellerHeader/Info';
import { Card } from '@hz-design-system/web-ui';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';

type TSellerHeaderProps = {
  sellerProfile: TSeller;
  onClickReviewLink: () => void;
  onClickSaveControl: (() => Promise<void>) | (() => false | void);
  saveControlLoading: boolean;
};

const classes = {
  root: getSuitCSSClasses({ namespace: 'hz', name: 'SellerHeader' }),
};

const SellerHeader = ({
  sellerProfile,
  onClickReviewLink,
  onClickSaveControl,
  saveControlLoading,
}: TSellerHeaderProps) => {
  const {
    userDetails: { id: userDetailsId },
  } = useContext(EnvironmentContext);

  if ((sellerProfile && Object.keys(sellerProfile).length === 0) || !sellerProfile) {
    return null;
  }

  const {
    id: sellerId,
    active,
    location,
    isSavedSeller,
    title,
    reviews,
    mission,
    backdropUrl,
    photo,
    periodSinceRegistrationDate,
    dsaInformation,
  } = sellerProfile;

  return (
    <section className={`${classes.root} hz-Page-element hz-Page-element--full-width`}>
      <Card>
        {!active && <TopSectionWithNoImagery title={title} />}
        {active && <TopSectionWithImagery title={title} mission={mission} backdropUrl={backdropUrl} photo={photo} />}
        <Info
          sellerId={sellerId}
          sellerLocation={location}
          isSavedSeller={isSavedSeller}
          reviews={reviews}
          periodSinceRegistrationDate={periodSinceRegistrationDate}
          isSaveSellerControlVisible={userDetailsId !== sellerId}
          onClickReviewLink={onClickReviewLink}
          onClickSaveControl={onClickSaveControl}
          saveControlLoading={saveControlLoading}
          dsaInformation={dsaInformation}
          paidProfile={active}
        />
        {active && <Tabs sellerId={sellerId} />}
      </Card>
    </section>
  );
};

export default SellerHeader;
