import replaceAll from '@/utils/strings/replaceAll';

/**
 * Replaces all occurrences of replaceObj's keys with their corresponding values in the passed-in string
 *
 * @param str
 * @param {object} replaceObj: <string_to_be_replaced, new_value>
 * @return {string}
 */
const replaceAllFromObject = (str, replaceObj) =>
  Object.keys(replaceObj).reduce((acc, target) => {
    const replaceBy = replaceObj[target];
    return replaceAll(acc, target, replaceBy);
  }, str);

export default replaceAllFromObject;
