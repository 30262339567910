import { request } from '@lrp/api-client';
// todo: this is very bad import, the lib need to export this types instead
// it's there as aurora-node-gpt-banners-data updating the global scope with types like TGptBannersData
import type {} from '@ecg-marktplaats/aurora-node-gpt-banners-data/types/index';

const baseURL = '/lrp/api';

const postParams = (xsrfToken: string) => {
  return {
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'x-mp-xsrf': xsrfToken, 'Content-type': 'application/json' },
  } as const;
};

export const sendTargetingEvent = (xsrfToken: string, data: any): Promise<{ segments: any }> =>
  request({
    ...postParams(xsrfToken),
    uri: `${baseURL}/audience-targeting`,
    body: JSON.stringify(data),
  });

export const getGptBannersServerData = ({
  xsrfToken,
  getGptBannersDataProps,
}: {
  xsrfToken: string;
  getGptBannersDataProps: TGptBannersData;
}): Promise<any> => {
  return request({
    ...postParams(xsrfToken),
    uri: `${baseURL}/banner-data`,
    body: JSON.stringify({ getGptBannersDataProps }),
  });
};
