import { request } from '@lrp/api-client';

type TProps = {
  xsrfToken: string;
  itemId: string;
  favorite: boolean;
};

const toggleFavorite = async ({ xsrfToken, itemId, favorite }: TProps): Promise<{ isFavorite: boolean }> => {
  try {
    return await request({
      uri: '/lrp/api/favorite',
      method: favorite ? 'POST' : 'DELETE',
      credentials: 'same-origin',
      headers: { 'x-mp-xsrf': xsrfToken, 'Content-Type': 'application/json' },
      body: JSON.stringify({ itemId }),
    });
  } catch (e) {
    return {
      // Return the previous state if request failed
      isFavorite: !favorite,
    };
  }
};

export default toggleFavorite;
