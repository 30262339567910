import React from 'react';
import { Asset } from '@hz-design-system/web-ui';

import energyLabels from '@/client/components/Listings/energyLables.json';

interface TenantContext {
  tenant: string;
  locale: string;
}

interface ListingEnergyLabelProps {
  rating: string;
  tenantContext: TenantContext;
  className?: string;
}

const ListingEnergyLabel: React.FC<ListingEnergyLabelProps> = ({
  rating,
  tenantContext: { tenant, locale },
  className,
}) => {
  const assetName = energyLabels[tenant][locale][rating];
  const modifiedClassName = className ? `EnergyLabel${tenant} ${className}` : `EnergyLabel${tenant}`;

  return <Asset.Fluid name={assetName} className={modifiedClassName} />;
};

export default ListingEnergyLabel;
