import { Location } from '@/client/types/Listings';

const locationFormatter = (
  { onCountryLevel, cityName, abroad, countryName, isBuyerLocation, countryAbbreviation }: Location,
  i18n,
) => {
  if (isBuyerLocation) {
    return i18n.t('location.shipsTo', { cityName });
  }
  if (onCountryLevel && abroad && countryName && !isBuyerLocation) {
    return i18n.t('location.cityNation', { cityName, countryAbbreviation });
  }
  if (onCountryLevel && !abroad && !isBuyerLocation) {
    return i18n.t('location.nationwide');
  }
  if (onCountryLevel && abroad && !countryName && !isBuyerLocation) {
    return i18n.t('location.abroad');
  }
  return cityName;
};

export default locationFormatter;
