import { logger } from '@lrp/logger';
import updateDataLayer from '@/HOC/utils/analytics/updateDataLayer';
import getDataLayerObject from '@/HOC/utils/getDataLayerObject';

declare global {
  interface Window {
    ecGaInitialId: string;
  }
}

let isGAInitCalled = false;

export default function updateDataLayerAndInitGA(searchRequestAndResponse: TSearchRequestAndResponse, locale: any) {
  const { attributeHierarchy, facets, totalResultCount, searchRequest, searchCategory } = searchRequestAndResponse;
  const dataLayer = getDataLayerObject({
    locale,
    attributeHierarchy,
    facets,
    totalResultCount,
    searchCategory,
    searchRequest,
  });

  updateDataLayer(dataLayer);
  if (isGAInitCalled) {
    window.ecGa('trackPage');
  } else {
    try {
      window.ecGa('init', window.ecGaInitialId);
      isGAInitCalled = true;
    } catch (error) {
      logger.error('Error in initializing GA', error);
    }
  }
}
