const equality = (x: any, y: any): boolean => x === y;

const removeDuplicates = <T>(arr: T[], comparator: (x: T, y: T) => boolean = equality): T[] =>
  arr.reduce((acc: T[], item: T) => {
    if (!acc.find((x: T) => comparator(x, item))) {
      return acc.concat(item);
    }
    return acc;
  }, []);

export default removeDuplicates;
