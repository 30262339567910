import React, { useState } from 'react';
import {
  Card,
  Link,
  StarRating,
  Icon,
  PopOverWrapper,
  PopOverTrigger,
  PopOverContent,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Divider,
} from '@hz-design-system/web-ui';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import SaveSellerControl from '@/client/components/SellerHeader/SaveSellerControl';
import getIsMobile from '@/client/utils/browser/isMobile';

type TInfoProps = {
  sellerId: number;
  sellerLocation?: string;
  reviews?: {
    count: number;
    score: number;
    renderLink: boolean;
  } | null;
  isSavedSeller: boolean;
  onClickReviewLink: () => void;
  onClickSaveControl: (() => Promise<void>) | (() => false | void);
  isSaveSellerControlVisible: boolean;
  saveControlLoading?: boolean;
  periodSinceRegistrationDate: string;
  dsaInformation?: TDsaInformation;
  paidProfile: boolean;
};

const Info = ({
  sellerId,
  sellerLocation = '',
  reviews = null,
  isSavedSeller,
  onClickReviewLink,
  isSaveSellerControlVisible,
  onClickSaveControl,
  saveControlLoading = false,
  periodSinceRegistrationDate,
  dsaInformation,
  paidProfile,
}: TInfoProps) => {
  const { t } = useI18nContext();
  const [shouldShowPopover, setShouldShowPopover] = useState(true);
  const { approved, companyRegistrationName, taxNumber, phoneNumber, email, streetAndHouseNumber, postcode, city } =
    dsaInformation || {};

  const popOverHandler = () => {
    setShouldShowPopover(!shouldShowPopover);
  };

  const handleClickButton = (evt) => {
    evt.preventDefault();
    onClickSaveControl();
  };

  const handleClickReviewsLink = (evt) => {
    evt.preventDefault();
    onClickReviewLink();
  };

  const safeReviewScore = (score: number) => {
    const MAX_VALID_VALUE = 5;
    const MIN_VALID_VALUE = 0;
    if (score > MAX_VALID_VALUE) return MAX_VALID_VALUE;
    if (score < MIN_VALID_VALUE) return MIN_VALID_VALUE;

    return score;
  };

  const PaidProfileInfoComponent = () => (
    <Card className="seller-header-info-paid">
      {reviews && (
        <div>
          <div className="left">
            <StarRating rating={safeReviewScore(reviews.score)} />
          </div>
          <div className="item">
            {/* TODO: Everything should be a link eventually */}
            {reviews.renderLink && (
              <Link className="dialog-link" onClick={handleClickReviewsLink} href="">
                <span className="review-number" />
                {`${reviews.count} ${t('soi.sellerHeader.experiences')}`}
              </Link>
            )}
            {!reviews.renderLink && <span>{`${reviews.count} ${t('soi.sellerHeader.experiences')}`}</span>}
          </div>
        </div>
      )}
      <Trans className="item" i18nKey="soi.sellerHeader.joinedSince" value={periodSinceRegistrationDate} />
      {sellerLocation && (
        <div className="item">
          <Icon name="location" size="sm" />
          {sellerLocation}
        </div>
      )}
      {isSaveSellerControlVisible && (
        <SaveSellerControl
          sellerId={sellerId}
          isSavedSeller={isSavedSeller}
          onClick={handleClickButton}
          loading={saveControlLoading}
        />
      )}
    </Card>
  );

  const FreeProfileInfoComponent = () => (
    <>
      {getIsMobile() && approved ? (
        <Accordion className="seller-header-info-free__mobile-dsa">
          <AccordionItem>
            <AccordionHeader>
              <div className="accordion-header">
                <div>
                  <Trans
                    className="joined-since"
                    i18nKey="soi.sellerHeader.joinedSince"
                    value={periodSinceRegistrationDate}
                  />
                  {reviews && (
                    <div className="reviews">
                      <div>
                        <StarRating rating={safeReviewScore(reviews.score)} />
                      </div>
                      <div className="item">
                        {reviews.renderLink && (
                          <Link className="dialog-link" onClick={handleClickReviewsLink} href="">
                            {`${reviews.count} ${t('soi.sellerHeader.experiences')}`}
                          </Link>
                        )}
                        {!reviews.renderLink && <span>{`${reviews.count} ${t('soi.sellerHeader.experiences')}`}</span>}
                      </div>
                    </div>
                  )}
                </div>
                {isSaveSellerControlVisible && (
                  <SaveSellerControl
                    sellerId={sellerId}
                    isSavedSeller={isSavedSeller}
                    onClick={handleClickButton}
                    loading={saveControlLoading}
                  />
                )}
              </div>
            </AccordionHeader>
            <AccordionBody className="accordion-body">
              <div className="accordion-body-content">
                <Divider />
                {companyRegistrationName && (
                  <div className="item">
                    <div className="value company-name">
                      <Trans tagName="span" i18nKey="dsa.companyName" />
                      {`: ${companyRegistrationName}`}
                    </div>
                  </div>
                )}
                {taxNumber && (
                  <div className="item">
                    <Trans tagName="div" i18nKey="dsa.taxNumber" />
                    <div className="value">{`: ${taxNumber}`}</div>
                  </div>
                )}
                <div className="item">
                  <Icon name="checkmark-circled-line" size="sm" className="icon" color="primary" />
                  <Trans className="value" tagName="div" i18nKey="dsa.approved.title" />
                </div>

                <Divider />

                {phoneNumber && (
                  <div className="item">
                    <Icon name="call" size="sm" className="icon" color="primary" />
                    <div className="value">{phoneNumber}</div>
                  </div>
                )}
                {email && (
                  <div className="item">
                    <Icon name="email" size="sm" className="icon" color="primary" />
                    <div className="value">{email}</div>
                  </div>
                )}
                {(streetAndHouseNumber || postcode || city) && (
                  <div className="item">
                    <Icon name="location" size="sm" className="icon" color="primary" />
                    <div>
                      <div className="value">{`${streetAndHouseNumber && streetAndHouseNumber}${(postcode || city) && `, ${postcode} ${city}`}`}</div>
                    </div>
                  </div>
                )}
              </div>
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      ) : (
        <Card className="seller-header-info-free__others">
          <div className="reviews-wrapper">
            {reviews && (
              <div className="reviews">
                <div>
                  <StarRating rating={safeReviewScore(reviews.score)} />
                </div>
                <div className="item">
                  {reviews.renderLink && (
                    <Link className="dialog-link" onClick={handleClickReviewsLink} href="">
                      {`${reviews.count} ${t('soi.sellerHeader.experiences')}`}
                    </Link>
                  )}
                  {!reviews.renderLink && <span>{`${reviews.count} ${t('soi.sellerHeader.experiences')}`}</span>}
                </div>
              </div>
            )}
            <Trans className="item" i18nKey="soi.sellerHeader.joinedSince" value={periodSinceRegistrationDate} />
          </div>

          {sellerLocation && !approved && (
            <div className="item">
              <Icon name="location" size="sm" />
              {sellerLocation}
            </div>
          )}
          {approved && (
            <div className="dsa-items">
              {companyRegistrationName && (
                <div className="item">
                  <Icon name="shop" size="sm" className="icon" />
                  <div>
                    <Trans className="title" tagName="div" i18nKey="dsa.companyName" />
                    <div className="value  company-name">{companyRegistrationName}</div>
                  </div>
                </div>
              )}
              {phoneNumber && (
                <div className="item">
                  <Icon name="call" size="sm" className="icon" />
                  <div>
                    <Trans className="title" tagName="div" i18nKey="dsa.phoneNumber" />
                    <div className="value">{phoneNumber}</div>
                  </div>
                </div>
              )}
              {email && (
                <div className="item">
                  <Icon name="email" size="sm" className="icon" />
                  <div>
                    <Trans className="title" tagName="div" i18nKey="dsa.emailAddress" />
                    <div className="value">{email}</div>
                  </div>
                </div>
              )}
              {(streetAndHouseNumber || postcode || city) && (
                <div className="item">
                  <Icon name="location" size="sm" className="icon" />
                  <div>
                    <Trans className="title" tagName="div" i18nKey="dsa.contactDetails" />
                    {streetAndHouseNumber && <div className="value">{streetAndHouseNumber}</div>}
                    {(postcode || city) && (
                      <div className="value">
                        {postcode}
                        {postcode && ' '}
                        {city}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {taxNumber && (
                <div className="item">
                  <Icon name="id" size="sm" className="icon" />
                  <div>
                    <Trans className="title" tagName="div" i18nKey="dsa.taxNumber" />
                    <div className="value">{taxNumber}</div>
                  </div>
                </div>
              )}
              <div className="eu-compliance-item">
                <div className="icon">
                  <Icon name="checkmark-circled-line" size="sm" />
                </div>
                <Trans className="title" tagName="div" i18nKey="dsa.approved.title" />
                <PopOverWrapper placement="bottom" openOnLoad={shouldShowPopover}>
                  <PopOverTrigger>
                    <Icon
                      onClick={popOverHandler}
                      name="info"
                      size="sm"
                      color="signal-action-default"
                      className="info-icon"
                    />
                  </PopOverTrigger>
                  <PopOverContent onClose={popOverHandler}>
                    <Trans className="dsaApprovedPopover" tagName="div" i18nKey="dsa.approved.description" />
                  </PopOverContent>
                </PopOverWrapper>
              </div>
            </div>
          )}
          {isSaveSellerControlVisible && (
            <SaveSellerControl
              sellerId={sellerId}
              isSavedSeller={isSavedSeller}
              onClick={handleClickButton}
              loading={saveControlLoading}
            />
          )}
        </Card>
      )}
    </>
  );

  return paidProfile ? <PaidProfileInfoComponent /> : <FreeProfileInfoComponent />;
};

export default Info;
