const trustIndicatorFormatter = ({ key, argumentValue }, i18n) => {
  const highlightKey = `listings.highlights.${key}`;
  if (key.toLowerCase() === 'warranty') {
    if (!argumentValue) {
      return i18n.t(highlightKey);
    }
    // TODO(chenreddy, Mar 2020): Avoid searching for dutch text
    if (argumentValue.toLowerCase().includes('garantie')) {
      return i18n.t(argumentValue);
    }
    return i18n.t(`listings.highlights.DetailedWarranty`, { warrantyLabel: argumentValue });
  }
  return i18n.t(highlightKey, argumentValue);
};

export default trustIndicatorFormatter;
