// Future improvement: use Intl.NumberFormat for different locales
const metersInKilometer = 1000;

const distanceKmFormatter = (distanceMeters) => {
  if (!distanceMeters || !Number.isInteger(distanceMeters) || distanceMeters <= 0) {
    return '';
  }

  return `${(distanceMeters / metersInKilometer).toFixed()} km`;
};

export default distanceKmFormatter;
