import React from 'react';
import { Tabs as TabsComponent, Tab } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

interface TabsProps {
  sellerId: number;
}

const Tabs: React.FC<TabsProps> = ({ sellerId }) => {
  const { t } = useI18nContext();

  const aboutUsUrl = `/smb-profile/profile/${sellerId}`;

  const handleTabClick = () => {
    window.location.href = aboutUsUrl;
  };

  return (
    <TabsComponent>
      <Tab onClick={handleTabClick} label={t('soi.sellerHeader.aboutUs')} />
      <Tab isActive label={t('soi.sellerHeader.allAds')} />
    </TabsComponent>
  );
};

export default Tabs;
