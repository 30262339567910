/**
 * update dataLayerObject based on request data
 * @param {object} categories
 * @param {object} location
 * @param {string} vurl
 * @param {string} pageType
 * @param {object} attributes
 * @param {string} query
 * @param {number|string} pageNumber
 * @param {number|string} pageSize
 * @param {object} sortOptions
 * @param {number|string} totalResultCount
 * @param {object} priceRange
 * @param {string} language in format <lang>-<country> e.g. fr-BE
 */
import categoriesToDataLayerCategories from '@/utils/analytics/categoriesToDataLayerCategories';

const updateDataLayer = ({
  categories,
  dominantCategory,
  location,
  vurl,
  pageType,
  attributes,
  query,
  pageNumber,
  pageSize,
  sortOptions,
  totalResultCount,
  priceRange,
  viewKind,
  locale,
}) => {
  const newDataLayerObject = {
    c: categoriesToDataLayerCategories(categories), // Explicitly override the categories
    l: {
      d: location.distance || 0,
      pcid: location.postcode || '',
    },
    p: {
      vurl,
      t: pageType,
      vt: viewKind,
      lng: locale,
    },
    s: {
      attr: JSON.stringify(attributes),
      kw: query,
      pn: pageNumber,
      ps: pageSize,
      srt: {
        dr: sortOptions.sortOrder,
        f: sortOptions.sortBy,
      },
      tr: totalResultCount,
      ...(priceRange && {
        prc: {
          ...(priceRange.from && { mn: priceRange.from }),
          ...(priceRange.to && { mx: priceRange.to }),
        },
      }),
      ...(Number.isInteger(dominantCategory) && {
        dc: dominantCategory,
      }),
    },
  };

  if (window.ecGa) {
    window.ecGa('pushToDataLayer', newDataLayerObject);
  } else {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(newDataLayerObject);
  }
};

export default updateDataLayer;
