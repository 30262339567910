/* eslint-disable camelcase */
// eslint-disable-next-line import/prefer-default-export

import { ImageSize } from '@ecg-marktplaats/media-util-js-lib';

export const MAX_HIGHLIGHTS_PER_AD = 2;
export const MAIN_IMAGE_SIZE_LIST_DESKTOP = ImageSize.Large;
export const MAIN_IMAGE_SIZE_LIST_MOBILE = ImageSize.Medium;
export const MAIN_IMAGE_SIZE_GALLERY = ImageSize.ExtraLarge;

export const IMAGE_ALT_TEXT_LENGTH_LIMIT = 125;
