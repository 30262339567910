/* eslint-disable camelcase */
export const BANNER_PARAMETERS = {
  RSI_SEGMENTS: 'wl',
  POSTCODE: 'pc',
  POSITION: 'pos',
  KEYWORD: 'kw',
  SESSION_ID: 'sessionId',
  SELLER_ID: 'sid',
};

export const BANNER_POSITIONS = {
  LRP_LIST_VIEW: ['lrp_top', 'lrp_bot', 'lrp_mid', 'lrp_left', 'lrp_left1'],
  LRP_GRID_VIEW: ['lrp_top', 'lrp_bot', 'lrp_gv_mid', 'lrp_left', 'lrp_left1'],
  SOI: ['soi_top'],
};

export const BANNER_PAGE_KEYS = {
  LRP: 'lrp',
  SOI: 'soi',
  SRP: 'srp',
};

export const BANNER_PAGE_VURLS = {
  LRP: 'ListingResultsPage',
  SRP: 'SearchResultsPage',
  SOI: 'SellerOtherItemsPage',
};
