import React, { useContext, useState } from 'react';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

import ReviewsDialog from '@/client/components/SellerHeader/ReviewsDialog';
import actionStatus from '@/client/actions/actionStatus';
import SellerHeader from '@/client/components/SellerHeader';
import saveSellerAction from '@/client/actions/SOI/saveSeller';
import deleteSellerAction from '@/client/actions/SOI/deleteSeller';
import trackingKeys from '@/client/constants/analytics/trackingKeys';
import trackEvent from '@/client/utils/analytics/trackEvent';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import { logger } from '@lrp/logger';

type TProps = {
  sellerProfile: TSeller;
  pushNotification: (type: string, message: React.ReactElement) => void;
};

const SellerHeaderContainer = ({ sellerProfile, pushNotification }: TProps) => {
  const { userDetails, xsrfToken } = useContext(EnvironmentContext);
  const [status, setStatus] = useState(actionStatus.initial);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSavedSeller, setIsSavedSeller] = useState(!!sellerProfile?.isSavedSeller);

  if ((sellerProfile && Object.keys(sellerProfile).length === 0) || !sellerProfile) {
    return null;
  }

  const saveSeller = async () => {
    trackEvent({
      eventAction: trackingKeys.FOLLOW_SELLER.EVENT_ACTION.BEGIN,
    });

    setStatus(actionStatus.fetching);

    try {
      await saveSellerAction(xsrfToken, sellerProfile?.id);

      const message = (
        <Trans i18nKey="soi.notifications.saveSellerSuccess" url="/my-account/favorite-sellers/index.html" />
      );

      pushNotification('success', message);

      trackEvent({
        eventAction: trackingKeys.FOLLOW_SELLER.EVENT_ACTION.SUCCESS,
      });

      setStatus(actionStatus.success);
      setIsSavedSeller(true);
    } catch (err) {
      logger.error('error while saving a seller', err);
      const message = <Trans i18nKey="soi.notifications.genericError" />;
      pushNotification('error', message);
      setStatus(actionStatus.failure);
    }
  };

  const deleteSeller = async () => {
    trackEvent({
      eventAction: trackingKeys.UNFOLLOW_SELLER.EVENT_ACTION.BEGIN,
    });

    setStatus(actionStatus.fetching);

    try {
      await deleteSellerAction(xsrfToken, sellerProfile?.id);

      const message = (
        <Trans i18nKey="soi.notifications.deleteSellerSuccess" url="/my-account/favorite-sellers/index.html" />
      );

      pushNotification('success', message);

      trackEvent({
        eventAction: trackingKeys.UNFOLLOW_SELLER.EVENT_ACTION.SUCCESS,
      });

      setStatus(actionStatus.success);
      setIsSavedSeller(false);
    } catch (err) {
      logger.error('error while deleting a seller', err);
      const message = <Trans i18nKey="soi.notifications.genericError" />;
      pushNotification('error', message);
      setStatus(actionStatus.failure);
    }
  };

  const toggleSaveSeller = () => {
    if (!userDetails.isLoggedIn) {
      return () =>
        typeof window !== 'undefined' &&
        window.location.assign(`/account/login.html?target=${encodeURIComponent(window.location.href)}`);
    }
    return isSavedSeller ? deleteSeller : saveSeller;
  };

  // Specific to SOI Page.
  const toggleModalOpen = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <SellerHeader
        sellerProfile={{ ...sellerProfile, isSavedSeller }}
        onClickReviewLink={toggleModalOpen}
        onClickSaveControl={toggleSaveSeller()}
        saveControlLoading={status === actionStatus.fetching}
      />
      {isModalOpen && <ReviewsDialog sellerId={sellerProfile.id} onClose={toggleModalOpen} />}
    </>
  );
};

export default SellerHeaderContainer;
