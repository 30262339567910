import React, { Suspense } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import actionStatus from '@/client/actions/actionStatus';
import Alert from '@/client/components/Alert/Alert';

type TProps = {
  notification?: {
    type: string;
    message?: React.ReactElement;
  };
  requestStatus?: string;
};

const Notification = ({ notification, requestStatus }: TProps) => {
  const { t } = useI18nContext();

  if (!notification && requestStatus !== actionStatus.failure) {
    return null;
  }

  const { type, message } = notification || {
    type: 'error',
    message: t('messages.generic_error'),
  };

  return (
    <Suspense fallback={'null'}>
      <div className="hz-Page-element hz-Page-element--full-width" data-testid="notification">
        <Alert kind={type} body={message} />
      </div>
    </Suspense>
  );
};

export default Notification;
