import { CATEGORIES_L2, DDI_EXCLUDED_CATEGORIES } from '@/constants/categories';

function isExcludedCategory(categoryId: number | undefined): boolean {
  if (
    !categoryId ||
    categoryId === CATEGORIES_L2.DIERENVOEDING ||
    categoryId === CATEGORIES_L2.OVERIGE_DIEREN_ACCESSOIRES
  ) {
    return false;
  }

  return DDI_EXCLUDED_CATEGORIES.has(categoryId);
}

export default isExcludedCategory;
